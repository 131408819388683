/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from "react";
import ReactQuill, { prototype } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import { MdAdd, MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";

import PropTypes from "prop-types";
import { jobOpeningValidation } from "redux/validator/admin/jobOpening";
import "./styles.css";
import { addJobOpening, fetchJobOpeningDataById, setIndividualJobOpening, updateJobOpeningData } from "reduxToolkit/JobSlice";
import { optionLocation } from "utils/Constants";
import { ClickOutsideClose } from "component/common/ClickOutsideClose";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { list: "check" },
      { script: "sub" },
      { script: "super" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    [{ color: [] }, { background: [] }], // Custom color picker
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export default function JobsForm({ setAddJobOpeningPop, id, setSubmit, setEditId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isClickedForPop, setIsClickedForPop] = useState(true);
  const inputRefs = useRef([]);
  const editData = useSelector((state) => state?.jobs.individualOpening);
  const { indiviualStatus } = useSelector((state) => state.jobs);
  const [inputList, setInputList] = useState([]);
  const [onSelect, setOnSelect] = useState(false);
  const [locationName, setLocationName] = useState([]);
  const locationMap = locationName?.map((item) => item)
  const [locationOpen, setLocationOpen] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    if (id) {
      dispatch(fetchJobOpeningDataById(id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      setInputList(editData?.benefits || []);
      if (editData?.location && id) {
        setLocationName(editData.location.split(", ").filter(Boolean));
      }
    }
  }, [editData]);

  const initialValues = {
    title: id ? editData.title : "",
    description: id ? editData.description : "",
    requirements: id ? editData.requirements : "",
    vacancyCount: id ? Number(editData.vacancy) : "",
    jobType: id ? editData.job_type : "",
    status: id ? editData.status : "",
    location: id ? editData?.location?.split(', ') : "",
    benefits: id ? [] : inputList,
  };
  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: jobOpeningValidation,
    onSubmit: async (values) => {

      const jsonObj = {
        job_opening: {
          title: values?.title,
          description: values.description,
          requirements: `${values?.requirements || 0}`,
          vacancy_count: values?.vacancyCount,
          job_type: values?.jobType,
          status: values?.status,
          location: locationName.join(", "),
          benefits: inputList.filter((input) => input.trim() !== ""),
        },
      };

      if (id) {
        dispatch(updateJobOpeningData(jsonObj.job_opening, id, setAddJobOpeningPop, setEditId));
      } else {
        dispatch(addJobOpening(jsonObj.job_opening, setAddJobOpeningPop, setEditId));
      }
    },
  });

  const { values, handleChange, handleSubmit, setFieldValue, handleBlur, errors, touched } =
    Formik;


  useEffect(() => {
    if (id) {
      Formik.setValues((prevValues) => ({
        ...prevValues,
        title: editData.title,
        jobType: editData.jobType,
        description: editData.description,
        requirements: editData.requirements,
        location: editData.location?.split(', '),
        vacancyCount: editData.vacancy,
        status: editData.status,
        benefits: editData.benefits,
      }));
    }
  }, [editData, id, setFieldValue]);

  const enterClick = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
    } else if (event.key === "Escape") {
      event.preventDefault();
      setAddJobOpeningPop();
    }
  };

  const handleClickLocation = (item) => {
    const itemValue = item?.value;

    if (locationName.includes(itemValue)) {
      setLocationName(locationName.filter(value => value !== itemValue));
      setFieldValue('location', locationName.filter(value => value !== itemValue));
    } else {
      const updatedLocationName = [...locationName, itemValue];
      setLocationName(updatedLocationName);
      setFieldValue('location', updatedLocationName);
    }
  };

  const handleLocationOpen = () => {
    if (isClickedForPop) {
      setLocationOpen(true);
      setIsClickedForPop(false);
    }
  }

  ClickOutsideClose(locationOpen, setIsClickedForPop, menuRef, () => setLocationOpen(false))

  const handleInputChange = (index, event) => {
    const newList = [...inputList];
    const inputValue = event.target.value.trim();
    if (inputValue.length <= 25) {
      newList[index] = event.target.value;
      setInputList(newList);
    }
  };
  const handleAddInput = () => {
    setInputList((prev) => [...prev, ""]);
  };

  const handleRemoveInput = (index) => {
    if (inputList.length > 0) {
      const newList = inputList.filter((input, _i) => index !== _i);
      setInputList(newList);
    }
  };
  const handleBenefitKeyDown = (index, event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setTimeout(() => {
        if (inputRefs.current[index]) {
          inputRefs.current[index].blur();
        }
      }, 0);
    }
  };

  return (
    <div
      className="w-full h-full flex items-center
  justify-end fixed top-0 left-0 z-20 bg-[rgba(3,27,89,.2)] "
    >
      <div
        className="w-[50%] h-full bg-white flex flex-col gap-5
      shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transitionRight "
      >
        <div className=" w-full h-[5rem] px-10 flex justify-between items-center border-b border-[#E2E8F0]">
          <h2 className="font-extrabold text-xl text-[#031B59]">
            {id ? t("editJob") : t("newJob")}
          </h2>
          <div
            className="border-2 p-1 text-[1.5rem] cursor-pointer"
            onClick={() => setAddJobOpeningPop()}
          >
            <RxCross2 />
          </div>
        </div>
        <div className="w-full h-[calc(100%-64px)] px-10 overflow-y-auto no-scrollbar">
          <form
            className="w-full  flex flex-col gap-3"
            id="job-opening-form"
            onSubmit={handleSubmit}
          >
            <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col space-y-2">
                <label
                  data-testid="jobName"
                  htmlFor="title"
                  className="text-[#313135]"
                >
                  {t("jobName")}
                </label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                  value={values?.title}
                  onChange={handleChange}
                  onKeyDown={(e) => enterClick(e)}
                />
                {errors?.title && touched?.title && (
                  <p className="text-[red]">{errors.title}</p>
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <label
                  data-testid="jobType"
                  htmlFor="job_type"
                  className="text-[#313135]"
                >
                  {t("jobType")}
                </label>
                <select
                  type="number"
                  name="jobType"
                  id="jobType"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded
                  p-[10px_14px_10px_10px] text-[#191919] bg-white"
                  value={values?.jobType}
                  onChange={handleChange}
                  onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">{t("select")}</option>
                  <option value="Work From Office">
                    {t("work_from_office")}
                  </option>
                  <option value="Remote">{t("remote")}</option>
                  <option value="Hybrid">{t("hybrid")}</option>
                </select>
                {errors?.jobType && touched?.jobType && (
                  <p className="text-[red]">{errors.jobType}</p>
                )}
              </div>
            </div>
            <div
              className="flex flex-col w-full h-fit col-start-1 xl:col-span-4 col-span-1 min-h-[10rem] gap-2
            text-[#646978] leading-[22px]"
            >
              <label
                data-testid="jobDescription"
                htmlFor="description"
                className="text-[#313135]"
              >
                {t("jobDescription")}
              </label>
              <div className="quill-editor-container">
                <ReactQuill
                  className="w-full h-fit"
                  placeholder="Content"
                  style={{ color: "#000000", minHeight: "100px" }}
                  modules={modules}
                  theme="snow"
                  value={values?.description}
                  onChange={(val) => {
                    setFieldValue("description", val.trim());
                  }}
                  onKeyDown={(e) => enterClick(e)}
                />
              </div>
            </div>
            {errors?.description && touched?.description && (
              <p className="text-[red]">{errors.description}</p>
            )}
            <div className="grid grid-cols-1 lg:grid lg:grid-cols-2 gap-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="requirements" className="text-[#313135]">
                  {t("experience")} (Years)
                </label>
                <div className="flex w-full rounded space-x-2">
                  <input
                    type="text"
                    name="requirements"
                    id="year"
                    className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px] text-[#191919] capitalize"
                    value={values?.requirements}
                    onChange={handleChange}
                    placeholder="Year"
                    required
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[a-zA-Z]/g, ''); 
                    }}
                    min="0"
                  />
                </div>
                {errors?.requirements && touched?.requirements && (
                  <p className="text-[red]">{errors.requirements}</p>
                )}
              </div>

              <div className="flex flex-col space-y-2 relative">
                <label htmlFor="location" className="text-[#313135]">
                  {t("preLocation")}
                </label>
                <div
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded
                   p-[10px_14px_10px_10px] text-[#191919] bg-white cursor-pointer relative"
                  name="location"
                  onClick={handleLocationOpen}
                >
                  {locationName.length > 0 ? (
                    locationName.join(', ')
                  ) : (
                    "Select Location"
                  )}
                  <div className="absolute top-3 font-bold right-0">
                    <MdKeyboardArrowDown className="text-[20px]" />
                  </div>
                </div>
                {locationOpen &&
                  <div ref={menuRef} className="absolute w-full top-[4.1rem] rounded-[0.5rem] py-2 px-4 bg-white border border-[#DEE4EB]">
                    {optionLocation?.map((item) => {
                      return (
                        <>
                          <div className="bg-white z-30 flex items-center gap-2 py-1">
                            <input type="checkbox" checked={locationName.includes(item.value)} className="cursor-pointer border bg-white rounded-[10rem] w-[1.2rem] h-[1.2rem]"
                              onChange={() => handleClickLocation(item)}
                            />
                            <p value={item?.value}>{item?.value}</p>
                          </div>
                        </>
                      )
                    })}
                  </div>
                }
                {errors?.location && touched?.location && (
                  <p className="text-[red]">{errors.location}</p>
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="vacancy_count" className="text-[#313135]">
                  {t("vacancy")}
                </label>
                <input
                  type="number"
                  name="vacancyCount"
                  id="vacancyCount"
                  value={values?.vacancyCount}
                  onChange={handleChange}
                  onKeyDown={(e) => enterClick(e)}
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded p-[10px_14px_10px_10px] text-[#191919]"
                />
                {errors?.vacancyCount && touched?.vacancyCount && (
                  <p className="text-[red]">{errors.vacancyCount}</p>
                )}
              </div>
              <div className="flex flex-col space-y-2">
                <label htmlFor="status" className="text-[#313135]">
                  {t("status")}
                </label>
                <select
                  name="status"
                  id="status"
                  className="h-[2.625rem] w-full border border-[#E2E8F0] rounded
                  p-[10px_14px_10px_10px] text-[#191919] bg-white cursor-pointer"
                  value={values?.status}
                  onChange={handleChange}
                  onKeyDown={(e) => enterClick(e)}
                >
                  <option value="">{t("select")}</option>
                  <option value="Open">{t("open")}</option>
                  <option value="Close">{t("close")}</option>
                </select>
                {errors?.status && touched?.status && (
                  <p className="text-[red]">{errors.status}</p>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col gap-2">
              <label htmlFor="benefits" className="text-[#313135]">
                {t("benefitsofworking")}
              </label>
              <div className="w-full flex flex-wrap gap-[8px]">
                {inputList?.map((input, index) => (
                  <div
                    key={index}
                    className="flex flex-row
                            items-center bg-[#E7F2E8] text-[#686868]
                            h-[32px] border border-[#1A8718] p-2 rounded-md"
                  >
                    <input
                      ref={(el) => (inputRefs.current[index] = el)}
                      className="w-fit px-2 bg-[#E7F2E8] border-none outline-none"
                      onChange={(e) => handleInputChange(index, e)}
                      value={input}
                      onKeyDown={(e) => handleBenefitKeyDown(index, e)}
                      maxLength={25}
                    ></input>
                    <div
                      className="cursor-pointer"
                      onClick={() => handleRemoveInput(index)}
                    >
                      <RxCross2 />
                    </div>
                  </div>
                ))}
                <div
                  className="flex h-[32px] items-center
                      rounded bg-[#F2F6FF] p-2 cursor-pointer"
                  onClick={handleAddInput}
                >
                  <MdAdd />
                  <span
                    data-testid="addmore"
                    className="text-[12px] leading-normal text-[#686868] cursor-pointer"
                  >
                    {t("addmore")}
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="w-full h-[5rem] px-5 flex items-center justify-end gap-1 bg-white border-t border-[#E2E8F0]">
          <button
            className="w-[7.625rem]  h-[2.688rem] flex items-center justify-center rounded-full text-[#686868]"
            onClick={() => setAddJobOpeningPop()}
          >
            {t("cancel")}
          </button>
          <button
            form="job-opening-form"
            data-testid="save"
            className="h-[2.813rem] w-[7.625rem] p-2 bg-[#23275E] text-white rounded-full"
            type="submit"
            disabled={indiviualStatus === "loading"}
            onClick={() => setOnSelect(true)}
          >
            {indiviualStatus === "loading" ? (
              <div className="flex items-center justify-center">
                <svg
                  className=" animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : id ? (
              t("update")
            ) : (
              t("add")
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

JobsForm.propTypes = {
  setAddJobOpeningPop: PropTypes.any,
  id: prototype.any,
};
