import useDebounce from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobApplications } from 'reduxToolkit/JobSlice';

const UseFetchJobSection = ({
  id,status,callback,againgetData,query,newId,permissionToFetch,clickCheck
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);

  useEffect(() => {
    const fetchJobsData = () => {
      // if (permissionToFetch.viewAll) {
        if (searchQuery){
          dispatch(getJobApplications(id, status,callback,searchQuery,newId));
        }
        else {
          dispatch(getJobApplications(id, status,callback,"","",clickCheck));
        }
      // }
    };
    fetchJobsData();
  }, [id,status,againgetData,searchQuery,clickCheck]);
};

export default UseFetchJobSection;
