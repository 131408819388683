import useDebounce from 'hooks/useDebounce';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getJobApplicantsData } from 'reduxToolkit/JobSlice';

const UseFetchJobApplicantsData = ({
  query, currentPage, status, setLoading, againgetData, permissionToFetch,clickCheck,itemsPagination
}) => {
  const dispatch = useDispatch();
  let searchQuery = useDebounce(query, 500);

  useEffect(() => {
    const fetchJobsData = () => {
      // if (permissionToFetch.viewAll) {
        if (searchQuery)
          dispatch(getJobApplicantsData(currentPage, status, setLoading,searchQuery,itemsPagination));
        else {
          dispatch(getJobApplicantsData(currentPage, status, setLoading,searchQuery,clickCheck,itemsPagination));
        }
      // }
    };
    fetchJobsData();
  }, [currentPage, status, againgetData, searchQuery,clickCheck,itemsPagination]);
};

export default UseFetchJobApplicantsData;
