import React, { useState } from "react";
import { AiFillFilePdf } from "react-icons/ai";
import { AiOutlineDownload } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { RiEdit2Line } from "react-icons/ri";
import ScheduleEventPopUp from "./ScheduleEventPopUp";
const ScheduleEventDetailPopUp = ({
  setPopup,
  isEditOpen,
  setIsEditOpen,
  handleEventModal,
  isScedulOpen,
  setEditPopup,
}) => {
  const handleEdit = () => {
    setPopup(false);
    setEditPopup(true);
  };

  const details = [
    { label: "Event Type", value: "Schedule" },
    { label: "Title", value: "UI/UX meeting" },
    { label: "Date", value: "26 December 2022" },
    { label: "Time", value: "11:00 AM - 11:30 AM" },
    {
      label: "Description",
      value:
        "Is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ];

  const guests = [
    { name: "Employee Name", imgSrc: "https://via.placeholder.com/40" },
    { name: "Employee Name 2", imgSrc: "https://via.placeholder.com/40" },
    { name: "Employee Name 3", imgSrc: "https://via.placeholder.com/40" },
  ];

  const documents = [
    {
      name: "Tanushree Resume.pdf",
      link: "/path/to/document.pdf",
      imgSrc: "https://via.placeholder.com/40",
    },
  ];

  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div
        className=" w-full max-w-lg h-full bg-white flex flex-col
     space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all pr-8"
      >
        <div className="w-full  items-center bg-white flex justify-between py-4">
          <div className="flex justify-center items-center">
            <h2 className=" text-[1.5rem] font-bold text-[#031B59] pl-8">
              Event Details
            </h2>
          </div>
          <div
            className="text-[1.5rem] cursor-pointer flex gap-2"
            // onClick={toggleAddEventModal}
          >
            <button onClick={handleEdit}>
              <RiEdit2Line />
            </button>
            {isEditOpen && (
              <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
                <div
                  className=" w-full max-w-[37rem] h-full bg-white flex flex-col
        space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all overflow-y-scroll"
                >
                  <div className="w-full h-[42px] items-center bg-white flex justify-between p-7">
                    <div className="flex justify-center items-center pl-4">
                      <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                        Add Event
                      </h2>
                    </div>
                    <div
                      className="text-[1.5rem] cursor-pointer mr-[-.64rem]"
                      onClick={handleEventModal}
                    >
                      <span
                        className="pr-4 flex"
                        onClick={() => isScedulOpen(false)}
                      >
                        <RxCross2 />
                      </span>
                    </div>
                  </div>

                  <div className="px-8  w-[34rem] ml-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Event Type
                    </label>
                    <input
                      type="text"
                      placeholder="Schedule"
                      className="mt-1 block  p-2 border border-gray-300 rounded-md w-[31rem]"
                    />
                  </div>
                  <div className="px-8">
                    <ScheduleEventPopUp handleClose={setIsEditOpen} />
                  </div>
                </div>
              </div>
            )}

            <button onClick={() => isScedulOpen(false)}>
              <RxCross2 />
            </button>
          </div>
        </div>
        <div className="max-w-xl  p-4 bg-white shadow-lg rounded-lg h-full pl-8">
          <div className="flex flex-col gap-4">
            {details.map((detail, index) => (
              <div className="flex flex-col gap-1" key={index}>
                <h2 className="text-gray-500 text-sm">{detail.label}</h2>
                <p
                  className={`text-base ${
                    detail.label === "Description"
                      ? "text-gray-700"
                      : "font-normal"
                  }`}
                >
                  {detail.value}
                </p>
              </div>
            ))}
          </div>
          <div className="mb-4">
            <h2 className="text-gray-500 text-sm pb-3">Guests</h2>
            {guests.map((guest, index) => (
              <div className="flex items-center mb-2" key={index}>
                <img
                  src={guest.imgSrc}
                  alt={`Guest ${index + 1}`}
                  className="w-6 h-6 rounded-full mr-3"
                />
                <p className="text-sm text-[#031B59] font-medium">
                  {guest.name}
                </p>
              </div>
            ))}
          </div>
          <div className="mb-4">
            <h2 className="text-gray-500 text-sm">URL</h2>
            <a
              href="https://meet.google.com"
              className="text-blue-500 underline"
            >
              https://meet.google.com
            </a>
          </div>
          <div className="mb-4">
            <h2 className="text-gray-500 text-sm">Uploaded Documents</h2>
            {documents.map((doc, index) => (
              <div
                className="flex gap-2 items-center border w-fit rounded-lg px-3 py-1 mt-2"
                key={index}
              >
                <img src={doc.imgSrc} alt="PDF" className="w-10 h-10" />
                <a href={doc.link} download className="text-[#1B4FB6] ">
                  <span className="text-[#A1A1A1] ">{doc.name}</span>
                </a>
                <span className="text-[#A1A1A1] text-xl">
                  <AiOutlineDownload />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleEventDetailPopUp;
