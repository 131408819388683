import React from "react";
import { RxCross2 } from "react-icons/rx";
import EventTypeDropdown from "./EventTypeDropdown";

const AddEventPopUp = ({ toggleAddEventModal }) => {
  console.log(toggleAddEventModal,"toggle");
  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div
        className=" w-full max-w-[37rem] h-full bg-white flex flex-col
       space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all"
      >
        <div className=" w-full h-[5rem] px-[8.8] flex justify-between items-center border-b border-[#E2E8F0]">
          <div className="w-full h-[42px] items-center bg-white flex justify-between p-7">
            <div className="flex justify-center items-center">
              <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                Add Event
              </h2>
            </div>
            <div
              className="text-[1.5rem] cursor-pointer"
              onClick={toggleAddEventModal}
            >
              
              <RxCross2 />
            </div>
          </div>
        </div>
        <EventTypeDropdown />
      </div>
    </div>
  );
};

export default AddEventPopUp;
