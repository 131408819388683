import DatePicker from "component/common/DatePicker";
import LoaderComp from "component/loader/LoaderComp";
import { useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { SlCloudUpload } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import { ScheduleInterview, updateEvent } from "reduxToolkit/JobSlice";
import * as Yup from "yup";

const ScheduleEventPopUp = ({ jobName, jobTitle, job, filterEvent, setAgainData, setIsopen, setFilterData, setInter, onClose }) => {
  const { t } = useTranslation();
  const [guestName, setGuestName] = useState("");
  const [formData, setFormData] = useState([]);
  const [formDataId, setFormDataId] = useState([]);
  const [guestTypeEmail, setGuestTypeEmail] = useState('');
  const { searchResult } = useSelector((state) => state.jobs);
  const [loder, setLoder] = useState(false);
  const formattedDate = filterEvent ? new Date(filterEvent.date).toISOString().split('T')[0] : '';
  const dispatch = useDispatch();
  let searchQuery = useDebounce(guestName, 300);
  const formatTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  };

  const formattedEndTime = formatTime(filterEvent?.endTime);
  const formattedStartTime = formatTime(filterEvent?.startTime);
  const removeGuest = (guest) => {
    const filterNewData = formData?.filter((item) => item?.id !== guest?.id);
    setFormData(filterNewData);
  };

  const selectEmployee = (employee) => {
    setFormData([...formData, { id: employee.id, full_name: employee.name }]);
    setGuestName('');
  };

  const [initialValues, setInitalValue] = useState({
    name: jobName,
    title: jobTitle,
    date: '',
    startTime: '',
    endTime: '',
    round: '',
    description: '',
    url: '',
    resume: job?.resumeUrl?.name,
  });

  const handleClose = () => {
    setFilterData(null);
    setInter(false);
    resetFormValues();
    onClose();
  };

  const resetFormValues = () => {
    setInitalValue({
      name: jobName,
      title: jobTitle,
      date: '',
      startTime: '',
      endTime: '',
      round: '',
      description: '',
      url: '',
      resume: job?.resumeUrl?.name,
    });
    setFormData([]);
    setGuestName('');
  };

  useEffect(() => {
    if (filterEvent) {
      setFormData(filterEvent?.guest);
      setInitalValue({
        name: jobName,
        title: jobTitle,
        date: filterEvent ? formattedDate : '',
        startTime: filterEvent ? formattedStartTime : '',
        endTime: filterEvent ? formattedEndTime : '',
        round: filterEvent ? filterEvent?.round : '',
        description: filterEvent ? filterEvent?.desc : '',
        url: filterEvent ? filterEvent?.url : '',
        resume: job?.resumeUrl?.name,
      });
    } else {
      setInitalValue({
        name: jobName,
        title: jobTitle,
        date: '',
        startTime: '',
        endTime: '',
        round: '',
        description: '',
        url: '',
        resume: job?.resumeUrl?.name,
      });
    }
  }, [filterEvent]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Please enter your name"),
    email: Yup.string().matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid email address"
    ),
    title: Yup.string()
      .required("Please add Title"),
    date: Yup.string()
      .required("Invalid date format"),
    startTime: Yup.string()
      .required("Please add start time"),
    endTime: Yup.string()
      .required("Please add end time")
      .test("is-greater", "End time should be greater than start time", function (value) {
        const { startTime } = this.parent;

        return startTime && value > startTime;
      }),
    round: Yup.string()
      .required("Please add rounds"),
    url: Yup.string()
      .required("Please add url"),
  });

  useEffect(() => {
    if (formData) {
      const formDataNew = formData?.map((item) => item.id);
      setFormDataId(formDataNew);
    }
 }, [formData]);

  const handleGuestEmail = (e) => {
    setGuestTypeEmail(e.target.value)
  }

  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForChat(searchQuery));
    }
  }, [searchQuery]);

  const guestTypeEmailArray = guestTypeEmail
    .split(',')
    .map(email => email.trim())
    .filter(email => email.length > 0);

  const Formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const newFile = new File(["foo"], job?.resumeUrl?.name, {
        type: job?.resumeUrl?.type,
      });
      const newForm = new FormData();
      if (filterEvent) {
        const json = {
          event: {
            event_type: "",
            title: values.title,
            date: values.date,
            start_time: values.startTime,
            end_time: values.endTime,
            description: values.description,
            guests_ids: formDataId,
            additional_emails: guestTypeEmailArray,
            meeting_url: values.url,
            event_documents_urls: newFile,
          },
        };
        dispatch(updateEvent(filterEvent?.id, json, setLoder, setIsopen, setAgainData));
      } else {
        newForm.append("event[title]", values.title);
        newForm.append("event[date]", values.date);
        newForm.append("event[start_time]", values.startTime);
        newForm.append("event[end_time]", values.endTime);
        newForm.append("event[description]", values.description);
        newForm.append("event[guests_ids]", JSON.stringify(formDataId));
        newForm.append("event[additional_emails][]", guestTypeEmail);
        newForm.append("event[meeting_url]", values.url);
        newForm.append("event[event_documents]", newFile);
        dispatch(ScheduleInterview(newForm, setIsopen, setLoder, setAgainData));
      }

    },
  });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    Formik;
  return (
    <div className="p-4 pt-0">
      <form className="space-y-4 overflow-y-auto custom_scroll" onSubmit={handleSubmit}>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Placeholder"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <DatePicker
            name="date"
            value={values?.date}
            handleChange={handleChange}
            onBlur={handleBlur}
            min={new Date().toISOString().split('T')[0]}
            className="h-[2.625rem] w-full"
          />
          {errors.date && touched.date ? (
            <p className="text-[red] w-80">{errors.date}</p>
          ) : null}
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("startTime")}
            </label>
            <input
              name="startTime"
              value={values.startTime}
              onChange={handleChange}
              className="w-full p-2 border"
              type="time"
            />
            {errors.startTime && touched.startTime ? (
              <p className="text-[red] w-80">{errors.startTime}</p>
            ) : null}
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              {t("endTime")}
            </label>
            <div className="w-full relative">
              <input
                name="endTime"
                value={values.endTime}
                onChange={handleChange}
                className="w-full p-2 border"
                type="time"
              />
              {errors.endTime && touched.endTime ? (
                <p className="text-[red] w-80">{errors.endTime}</p>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t("discription")}
          </label>
          <textarea
            name="description"
            value={values.description}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
            rows="4"
          ></textarea>
        </div>
        <div className='w-full'>
          <label className="block text-[#313135]">{t("addGuest")}</label>
          <div className="w-full border rounded-md">
            <div className="w-full flex gap-2">
              {formData?.map((guest, index) => (
                <div key={index} className="flex items-center w-fit gap-3 bg-[#031b59]
                         text-white p-1 rounded-md">
                  <span className="">{guest?.full_name}</span>
                  <AiOutlineClose className="cursor-pointer" onClick={() => removeGuest(guest)} />
                </div>
              ))}
              <input value={guestName}
                className=" flex-1 p-2"
                type="text"
                placeholder="Add guest"
                onChange={(e) => setGuestName(e.target.value)}
              />
            </div>
          </div>
          {
            guestName !== "" && (
              <div className="absolute flex justify-start flex-col
      w-full max-w-[36rem] bg-white gap-3
      shadow-[0px_0px_20px_0px_rgba(3,27,89,0.20)] p-[8px] rounded-md z-10 overflow-y-auto max-h-80">
                {searchResult.length > 0 ?
                  (searchResult.map((employee) => (
                    <div
                      key={employee.id}
                      onClick={(e) => selectEmployee(employee, e)}
                      className="flex items-center
                           text-black h-[3rem] px-2 cursor-pointer hover:bg-gray-100"
                    >
                      <img
                        src={`https://ui-avatars.com/api/?name=${employee.name}&background=random`}
                        alt="Profile Avatar"
                        className="w-8 h-8 rounded-full mr-2"
                      />

                      <p>{employee.name}</p>
                    </div>
                  ))) : (
                    <div> {t("noDataFound")}</div>
                  )
                }
              </div>
            )
          }

        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">{t("url")}</label>
          <input
            name="url"
            value={values?.url}
            onChange={handleChange}
            onBlur={handleBlur}
            className="w-full p-2 border rounded-md"
            type="url"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {t("uploadDocument")}
          </label>
          <div className="flex flex-col  border border-gray-300 rounded-md p-4 items-center mt-1">
            <SlCloudUpload />
            <p className="font-bold">{t("Draganddropfilesorbrowsefiles")}</p>
          </div>
        </div>
        <div className="flex justify-end">
          <div>
            {
              loder ? <LoaderComp /> : (
                <button type="submit" className="px-6 py-3 bg-[#031B59]
                      text-white text-lg rounded-[2.5rem] flex items-center">
                  {t("schedule")}
                </button>
              )
            }
          </div>
        </div>
      </form>
    </div>
  );
};

export default ScheduleEventPopUp;
