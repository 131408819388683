import React, { useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import StarRating from './StarRatings';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LoaderComp from 'component/loader/LoaderComp';
import { useTranslation } from 'react-i18next';
import { AddFeedBack, UpdateFeedBack, getfeeback } from 'reduxToolkit/JobSlice';

const WriteFeedBack = ({ setAgainData, jobId, closeRightPopup, editData, setEditData, job }) => {
  const dispatch = useDispatch();
  const [loder, setLoder] = useState(false);
  const [rating, setRating] = useState({
    "Technical Skills": editData ? editData?.technicalskills : 0,
    "Soft Skills": editData ? editData?.soft_skills : 0,
    "Experience and Knowledge": editData ? editData?.experienceandknowledge : 0,
    "Problem-Solving and Critical Thinking": editData ? editData?.problemsolvingandcriticalthinking : 0,
    "Project Management": editData ? editData?.projectmanagement : 0,
    "Cultural Fit": editData ? editData?.culturalfit : 0,
    "Performance Under Pressure": editData ? editData?.performanceunderpressure : 0,
    "Leadership and Initiative": editData ? editData?.leadershipandinitiative : 0,
  });
  const [description, setDescription] = useState(editData ? editData.description : "");
  const { t }
    = useTranslation();
  const handleRating = (index, skill) => {
    setRating({ ...rating, [skill]: index });
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const saveFeedBack = async () => {
    const data = {
      "job_application_feedbacks": {
        "description": description,
        "technical_skills": rating["Technical Skills"],
        "soft_skills": rating["Soft Skills"],
        "experience_and_knowledge": rating["Experience and Knowledge"],
        "problem_solving_and_critical_thinking": rating["Problem-Solving and Critical Thinking"],
        "project_management": rating["Project Management"],
        "leadership_and_initiative": rating["Leadership and Initiative"],
        "cultural_fit": rating["Cultural Fit"],
        "performance_under_pressure": rating["Performance Under Pressure"],
        "round": editData ? (editData?.round) : (job?.round ? job?.round : null),
      },
    };

    if (editData) {
      await dispatch(UpdateFeedBack(editData.id, data, setLoder, closeRightPopup));
      await dispatch(getfeeback(jobId, setLoder));
      await setEditData(null);
    }
    else {
      await dispatch(AddFeedBack(setAgainData,jobId, data, setLoder, closeRightPopup));
      await dispatch(getfeeback(jobId, setLoder));
    }
  };

  return (
    <div className="fixed top-0 right-0 h-full w-[40%] bg-white z-50 shadow-lg overflow-auto">
      <div className="relative w-full h-full p-5 flex flex-col justify-between">
        <div>
          <div className="w-full flex items-center justify-between">
            <button onClick={closeRightPopup} className="flex items-center">
              <BsChevronLeft className="h-3 w-4 stroke-1 cursor-pointer text-[#031B59]" />
              <span className="font-Roboto text-2xl text-[#031B59] font-extrabold ml-5">
                {t("writeFeed")}</span>
            </button>
          </div>
          <hr className="border-t border-gray-300 mt-4 w-full mb-4" />
          { job?.round ? (<div className="w-full bg-[#F2F6FF] flex items-center p-3 rounded-md">
            <button className='flex justify-between w-full items-center'>
              <h1>{editData ? editData?.round : job?.round}</h1>
            </button>
          </div>) : null}
          <h3 className="font-Roboto text-[1.4rem] font-bold mt-4 mb-4">{t("skillEvol")}</h3>
          <div className="font-Roboto text-lg text-[#686868] font-extrabold grid grid-cols-2 gap-4">
            {Object.keys(rating).map((skill, index) => (
              <div key={index} className="flex flex-col gap-2">
                <p className="font-Roboto text-lg text-[#686868] font-extrabold">{`${t(skill)}`}</p>
                <StarRating handleRating={handleRating} rating={rating[skill]} totalStars={5} skill={skill} />
              </div>
            ))}
          </div>
          <p className="font-Roboto text-md font-semibold text-[#A1A1A1] mt-5">
            {t("writeAddition")}</p>
          <textarea
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded-md resize-none"
            rows="4"
            value={description}
          ></textarea>
        </div>
        <div>
          <hr className="border-t border-gray-300 mt-4 w-full" />
          <div className="flex justify-end items-center gap-4 p-4">
            <button
              onClick={closeRightPopup}
              className="font-Roboto border px-5
               py-3 text-[#686868] font-extrabold text-lg rounded-md "
            >
              {t("cancel")}
            </button>
            {
              loder ? (<LoaderComp />) : (
                <button className={`px-6 py-3 ${Object.values(rating).every((value) => value === 0) ? "bg-gray-400" : "bg-[#031B59]"} text-white text-lg rounded-md flex items-center`}
                  disabled={Object.values(rating).every((value) => value === 0)}
                  onClick={saveFeedBack}>
                  {t("save")}
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriteFeedBack;

WriteFeedBack.propTypes = {
  jobId: PropTypes.number,
  closeRightPopup: PropTypes?.boolean,
  editData: PropTypes.any,
  setEditData: PropTypes.any,
};

