import { createSlice } from "@reduxjs/toolkit";
import ToastServices from "ToastServices";
import { getHost } from "api/Config";
import Helper from "api/Helper";
import apiUrl from "api/apiUrl";
import { farmatAllCount, farmatDetailActivity, fetchAllEvent, fetchAllJobApplications, fetchJobApplications, fetchJobData, fetchgetfeeback, formatAllJobOpeningApplied, formatGraphData, formatJobTitle, formatNewApplicants, formatOnboard, formatOnboardToday, getJobAppApplicants, singleJobData } from "redux/selector/Admin/jobOpening";

export const JobSlice = createSlice({
  name: "jobOpening",
  initialState: {
    isShowDialogBox: false,
    isShowAdditionDialogBox: false,
    isShowDeductionDialogBox: false,
    isSHowOtherDialogBox: false,
    isShowDisableDialogBox: false,
    scrollBody: false,
    individualOpening: {},
    newData: null,
    showNewGroupList: false,
    searchResult: [],
    clientInformation: {},
    jobOpenings: [],
    error: "",
    openingData: {},
    individualOpenings: {},
    jobOpeningStatus: "",
    indiviualStatus: "",
    jobApplications: {},
    allJobApplications: [],
    Getfeeback: [],
    isLoading: false,
    GetJobID: {},
    NewAllEvent: [],
    getAllApplicantsData: [],
    getCheckData: [],
    getAllOpeningsData: [],
    activityReduxState: [],
    allSetCount: {},
    allJobTitle: [],
    allDashboardOnboard: {},
    allDashboardOnboardToday: {},
    allNewApplicants: [],
    allGraphData: [],
    clickNewData: [],
    activityState:[]
  },
  reducers: {
    addData(state, action) {
      state.newData = action.payload;
    },
    isShowDialogBoxChange(state, action) {
      state.isShowDialogBox = action.payload;
    },
    isDeleteDialogBoxToggle(state, action) {
      state.isShowDisableDialogBox = action.payload;
    },
    setIndividualJobOpening(state, action) {
      state.individualOpening = action.payload;
    },
    setShowGroupList(state, action) {
      state.showNewGroupList = action.payload;
    },
    setSearchRsltForChat(state, action) {
      state.searchResult = action.payload;
    },
    setClientInformation(state, action) {
      state.clientInformation = action.payload;
    },
    setjobOpening(state, action) {
      state.jobOpenings = action.payload;
    },
    setjobApplication(state, action) {
      state.jobApplications = action.payload;
    },
    setAllApplications(state, action) {
      state.allJobApplications = action.payload;
    },
    setIndividualStatus(state, action) {
      state.indiviualStatus = action.payload;
    },
    isLoading(state, action) {
      state.isLoading = action.payload;
    },
    setJobOpeningStatus(state, action) {
      state.jobOpeningStatus = action.payload;
    },
    setAllApplicantsData(state, action) {
      state.getAllApplicantsData = action.payload;
    },
    setAllEvent(state, action) {
      state.NewAllEvent = action.payload;
    },
    setgetfeeback(state, action) {
      state.Getfeeback = action.payload;
    },
    setJobID(state, action) {
      state.GetJobID = action.payload;
    },
    setCheckData(state, action) {
      state.getCheckData = action.payload;
    },
    setAllJobData(state, action) {
      state.getAllOpeningsData = action.payload;
    },
    setAllCount(state, action) {
      state.allSetCount = action.payload;
    },
    setDashboardJobTitle(state, action) {
      state.allJobTitle = action.payload;
    },
    setDashboardOnboard(state, action) {
      state.allDashboardOnboard = action.payload;
    },
    setDashboardOnboardToday(state, action) {
      state.allDashboardOnboardToday = action.payload;
    },
    setDashboardNewApplicants(state, action) {
      state.allNewApplicants = action.payload;
    },
    setGraphData(state, action) {
      state.allGraphData = action.payload;
    },
    clickData(state, action) {
      state.clickNewData = action.payload;
    },
    setActivityAction(state, action) {
      state.activityReduxState = action.payload;
    },
  },
});

export const {
  addData,
  isShowDialogBoxChange,
  isDeleteDialogBoxToggle,
  setIndividualJobOpening,
  setShowGroupList,
  setSearchRsltForChat,
  setClientInformation,
  setjobOpening,
  setIndividualStatus,
  setAllApplications,
  isLoading,
  setJobOpeningStatus,
  setAllApplicantsData,
  setAllEvent,
  setgetfeeback,
  setJobID,
  setjobApplication,
  setCheckData,
  setAllJobData,
  setActivityAction,
  setAllCount,
  setDashboardJobTitle,
  setDashboardOnboard,
  setDashboardOnboardToday,
  setDashboardNewApplicants,
  setGraphData,
  clickData
} = JobSlice.actions;
export const jobReducer = JobSlice.reducer;



export const getJobApplications = (id, status, callback, searchQuery, newId, clickCheck) => async (dispatch) => {
  try {
    callback(true);
    let path;
    if (searchQuery) {
      path = apiUrl.jobApplications + `${newId?.id}&query=${searchQuery}&applicant_status=${status}`;
    }
    else if (clickCheck?.length > 0) {
      const idArr = clickCheck?.filter((item) => item.id !== null)?.map(item => item.id)
      path = apiUrl?.jobApplications + `${id}&applicant_status=${status}&handled_by=${idArr}`
    }
    else {
      path = apiUrl.jobApplications + `${id}&applicant_status=${status}`;
    }
    const { response } = await Helper.get(path);
    const formattedResponse = fetchJobApplications(response);
    dispatch(setjobApplication(formattedResponse));
    callback(false);
  } catch (error) {
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};


export const fetchJobOpeningData =
  (currentPage, itemsPerPage, searchQuery) => async (dispatch) => {
    try {
      dispatch(setJobOpeningStatus("loading"));
      const path = `${apiUrl.jobOpenings
        }?page=${currentPage}&per_page=${itemsPerPage}${searchQuery ? `&query=${searchQuery}` : ""
        }`;
      const { response } = await Helper.get(path);
      const formattedResponse = fetchJobData(response);
      dispatch(setjobOpening(formattedResponse));
      dispatch(setJobOpeningStatus(""));
    } catch (error) {
      dispatch(setJobOpeningStatus(""));
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error,
        type: "error",
      });
    }
  };

export const fetchJobOpeningDataById = (id) => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = apiUrl.jobOpenings + "/" + id;
    const { response } = await Helper.get(path);
    const formattedResponse = singleJobData(response);
    dispatch(setIndividualJobOpening(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const fetchAllJobOpeningForAppliedFor = () => async (dispatch) => {
  try {
    dispatch(isLoading(true));
    const path = "list_job_openings";
    const { response } = await Helper.get(path);
    const formattedResponse = formatAllJobOpeningApplied(response?.data);
    dispatch(setAllJobData(formattedResponse));
    dispatch(isLoading(false));
  } catch (error) {
    dispatch(isLoading(false));
    ToastServices.showToast({
      message: error,
      type: "error",
    });
  }
};

export const addJobOpening =
  (values, setAddJobOpeningPop, setEditId) => async (dispatch) => {
    try {
      let baseUrl = getHost();
      let token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
      dispatch(setIndividualStatus("loading"));
      const path = baseUrl + `/job_openings`;

      const response = await fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      });

      const status = response.status;
      const updatedaRes = await response.json();

      if (status === 200 || status === 201) {
        setEditId(null);
        ToastServices.showToast({
          message: "Successfully !",
          type: "success",
        });
        dispatch(setIndividualStatus("success"));
        dispatch(fetchJobOpeningData());
        setAddJobOpeningPop();
      } else {
        setEditId(null);
        throw new Error(
          `Request failed ${updatedaRes.error
            ? updatedaRes.error.title[0]
            : "something went wrong"
          }`
        );
      }
    } catch (error) {
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
      dispatch(setIndividualStatus(""));
      setAddJobOpeningPop();
    }
  };

export const updateJobOpeningData =
  (values, id, setAddJobOpeningPop) => async (dispatch) => {
    try {
      dispatch(setIndividualStatus("loading"));
      const path = apiUrl?.jobOpenings + "/" + `${id}`;
      const { status } = await Helper.put(values, path);
      if (status === 200) {
        ToastServices.showToast({
          message: "Successfully !",
          type: "success",
        });

        dispatch(setIndividualStatus("success"));
        setAddJobOpeningPop();
        dispatch(fetchJobOpeningDataById(id));
        dispatch(fetchJobOpeningData());
      } else {
        throw new Error(`Request failed with status ${status}`);
      }
    } catch (error) {
      dispatch(setIndividualStatus(""));
      setAddJobOpeningPop();
      ToastServices.showToast({
        message: "Error updating job opening",
        type: "error",
      });
    }
  };

export const JobOpeningStatusChange =
  (jobId, requestedStatus, handleConfimationClose) => async (dispatch) => {
    try {
      let obj = {
        job_opening: {
          status: requestedStatus,
        },
      };
      let baseUrl = getHost();
      let token = JSON.parse(localStorage.getItem("userLoginToken"))?.token;
      dispatch(setIndividualStatus("loading"));
      const path = baseUrl + `${apiUrl?.jobOpenings}/${jobId}`;

      const response = await fetch(path, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(obj),
      });
      const status = response.status;
      const updatedaRes = await response.json();

      if (status === 200 || status === 201) {
        ToastServices.showToast({
          message: "Successfully !",
          type: "success",
        });
        dispatch(setIndividualStatus("success"));
        handleConfimationClose();
        dispatch(fetchJobOpeningData());
      } else {
        handleConfimationClose();
        throw new Error(
          `Request failed ${updatedaRes.error
            ? updatedaRes.error.title[0]
            : "something went wrong"
          }`
        );
      }
    } catch (error) {
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
      dispatch(setIndividualStatus(""));
      handleConfimationClose();
    }
  };


export const getAllJobApplications =
  (currentPage, itemsPerPage) => async (dispatch) => {
    try {
      dispatch(isLoading(true));
      const path = `${apiUrl.allJobApplications}?&page=${currentPage}&per_page=${itemsPerPage}`;
      const { response } = await Helper.get(path);
      const formattedResponse = fetchAllJobApplications(response);
      dispatch(setAllApplications(formattedResponse));
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
    }
  };

export const onBoardinDateApi =
  (id, jsonObj, setOnBoardPop, setNewLoder, setAgainData) => async () => {
    try {
      setAgainData(false);
      setNewLoder(true);
      const path = `job_applications/${id}/set_onboarding`;
      const { response, status } = await Helper.patch(jsonObj, path);
      if (status === 200 || status === 201) {
        setNewLoder(false);
        setOnBoardPop(false);
        ToastServices.showToast({
          message: response?.message,
          type: "success",
          autoClose: 3000
        });
        setAgainData(true);
      }
    } catch (error) {
      setOnBoardPop(false);
      setNewLoder(false);
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
    }
  };


export const getJobApplicantsData = (currentPage, Astatus, setLoading,
  searchQuery, clickCheck, itemsPagination
) => async (dispatch) => {
  try {
    setLoading(true);
    let path;
    if (searchQuery !== '') {
      path = `${apiUrl.allJobApplications}?per_page=${itemsPagination}&page=${currentPage}&status=${Astatus}&query=${searchQuery}`;
    }
    else if (clickCheck?.length > 0) {
      const idArr = clickCheck?.filter((item) => item.id !== null)?.map(item => item.id)
      const nameArr = clickCheck?.filter(item => item.id === null)?.map(item => item.name)
      path = `job_applications?per_page=${itemsPagination}&page=${currentPage}&status=${Astatus}&job_titles=${nameArr}&handled_by=${idArr}`
    }
    else {
      path = `job_applications?per_page=${itemsPagination}&page=${currentPage}&status=${Astatus}`;
    }
    const { response, status } = await Helper.get(path);
    const formattedResponse = getJobAppApplicants(response);
    dispatch(setAllApplicantsData(formattedResponse));
    if (status === 200 || status === 201) {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    ToastServices.showToast({
      message: error.message,
      type: "error",
      autoClose: 3000,
    });
  }
};

export const updateApplicantData = (id, formData, setAgainData, setInsideLoder) => async () => {
  try {
    setInsideLoder(true);
    setAgainData(false);
    const path = `job_applications/${id}`;
    const { status } = await Helper.put(formData, path, true);
    if (status === 200 || status === 201) {
      setAgainData(true);
      setInsideLoder(false);
      ToastServices.showToast({
        message: "Move succecfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    setInsideLoder(false);
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const updateDropdownStatus = (formData, setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = "/update_job_application_status";
    const { status } = await Helper.put(formData, path);
    if (status === 200 || status === 201) {
      setAgainData(true);
      ToastServices.showToast({
        message: "Move succecfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const getfeeback =
  (id, setLoder) => async (dispatch) => {
    try {
      setLoder(true);
      dispatch(isLoading(true));
      const path = `job_application_feedbacks/${id}`;
      const { response, status } = await Helper.get(path);
      const formattedResponse = fetchgetfeeback(response);
      dispatch(setgetfeeback(formattedResponse));
      if (status === 200 || status === 201) {
        setLoder(false);
        dispatch(isLoading(false));
      }
    } catch (error) {
      dispatch(isLoading(false));
      ToastServices.showToast({
        message: error.message,
        type: "error",
      });
    }
  };

export const AddFeedBack = (setAgainData, id, data, setLoder, closeRightPopup) => async () => {
  try {
    setAgainData(false);
    setLoder(true);
    const path = `job_application_feedbacks/${id}`;
    const { status } = await Helper.post(data, path);
    if (status === 200 || status === 201) {
      setAgainData(true);
      setLoder(false);
      closeRightPopup();
      ToastServices.showToast({
        message: "Feedback Add succecfully !",
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const UpdateFeedBack = (id, data, setLoder, closeRightPopup) => async () => {
  try {
    setLoder(true);
    const path = `job_application_feedbacks/${id}`;
    const { response, status } = await Helper.put(data, path);
    if (status === 200 || status === 201) {
      setLoder(false);
      closeRightPopup();
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const DeleteFeedback = (id, setWait, setDropdownIndex) => async () => {
  try {
    setWait(true);
    const path = `/job_application_feedbacks/${id}`;
    const { response, status } = await Helper.delete(path);
    if (status === 200 || status === 201) {
      setWait(false);
      setDropdownIndex(null);
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const DeleteSelectEmployee = (id) => async (dispatch) => {
  try {
    const path = `job_applications/${id}`;
    const { status } = await Helper.delete(path);
    if (status === 200 || status === 201) {
      dispatch(dashboardOnboard());
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const ScheduleInterview = (formData, setIsopen, setLoder, setAgainData) => async () => {
  try {
    setLoder(true);
    setAgainData(false);
    const path = apiUrl.events;
    const { status } = await Helper.post(formData, path, true);
    if (status === 200 || status === 201) {
      setIsopen(false);
      setLoder(false);
      ToastServices.showToast({
        message: "Interview Schedule Successfully !",
        type: "success",
        autoClose: 3000,
      });
      setAgainData(true);
    } else {
      setLoder(true);
      ToastServices.showToast({
        message: "Something Went Wrong !",
        type: "warning",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const getAllEvent = () => async (dispatch) => {
  try {
    const path = apiUrl.events;
    const { response } = await Helper.get(path);
    const fetchAllData = fetchAllEvent(response);
    dispatch(setAllEvent(fetchAllData));
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const updateEvent = (id, formData, setLoder, setIsopen, setAgainData) => async () => {
  try {
    setLoder(true);
    setAgainData(false);
    const path = `events/${id}`;
    const { status } = await Helper.put(formData, path);
    if (status === 200 || status === 201) {
      setLoder(false);
      ToastServices.showToast({
        message: "Interview Edited",
        type: "success",
      });
      setAgainData(true);
      setIsopen(false);
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const removeHandlers = (id, userIds, setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `job_applications/${id}/remove_handlers`;
    const data = { "user_ids": [userIds] };
    const { response, status } = await Helper.post(data, path);
    if (status === 200 || status === 201) {
      setAgainData(true);
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  }
  catch (error) {
    ToastServices.showToast({
      message: error.message,
      autoClose: 3000,
    });
  }
};

export const assignResourceToAUser = (id, userIds, setAddResource, setAgainData) => async () => {
  try {
    setAgainData(false);
    const path = `/job_applications/${id.userId}/assign_handlers`;
    const data = { "user_ids": userIds };
    const { response, status } = await Helper.post(data, path);
    if (status === 200 || status === 210) {
      setAddResource(null);
      setAgainData(true);
      ToastServices.showToast({
        message: response.message,
        type: "success",
        autoClose: 3000,
      });
    }
  } catch (error) {
    ToastServices.showToast({
      message: error.message,
      type: "error",
    });
  }
};

export const fetchActivity = (id, setLoder) => async (dispatch) => {
  try {
    setLoder(true);
    const path = `activities?job_application_id=${id}`;
    const { response, status } = await Helper.get(path);
    const formatActivity = farmatDetailActivity(response?.length > 0 ? response : []);
    dispatch(setActivityAction(formatActivity))
    if (status === 200 || status === 201) {
      setLoder(false);
    }
  } catch (error) {
    setLoder(false);
    ToastServices.showToast({
      message: "No Activity Found",
      type: "error",
    });
  }
};

export const dashboardAllCount = () => async (dispatch) => {
  try {
    const path = `job_applications/all_job_count`;
    const { response } = await Helper.get(path);
    const formatedCount = farmatAllCount(response ? response : {});
    dispatch(setAllCount(formatedCount));
  } catch (error) {
    ToastServices.showToast({
      message: "No Activity Found",
      type: "error",
    });
  }
};

export const dashboardJobTitle = (searchQuery, setLoading) => async (dispatch) => {
  try {
    setLoading(true)
    let path;
    if(searchQuery) {
      path = `job_openings/current_opening_count?query=${searchQuery}`
    }
    else {
      path = `job_openings/current_opening_count`
    }
    const { response } = await Helper.get(path);
    const formatedJobTitle = formatJobTitle(response ? response : {});
    dispatch(setDashboardJobTitle(formatedJobTitle));
  } catch (error) {
    ToastServices.showToast({
      message: "No Activity Found",
      type: "error",
    });
  }finally{
    setLoading(false)
  }
};

export const dashboardOnboard = () =>
  async (dispatch) => {
    try {
      let path = `job_openings/todays_onboarding`
      // }
      const { response, status } = await Helper.get(path);
      const formatedOnboard = formatOnboard(
        response ? response : []
      );
      
      dispatch(setDashboardOnboard(formatedOnboard));
    } catch (error) {
      // setLoder(false);
      ToastServices.showToast({
        message: "No Activity Found",
        type: "error",
      });
    }
  }


export const dashboardNewApplicants = () => async (dispatch) => {
  const path = `job_openings/new_applications`;
  const { response, status } = await Helper.get(path);
  try {
    const formatedOnboard = formatNewApplicants(response ? response : []);
    dispatch(setDashboardNewApplicants(formatedOnboard))

  } catch (error) {
    ToastServices.showToast({
      message: "No Activity Found",
      type: "error",
    });
  }

};

export const dashboardOnboardToday = (searchQuery, clickCheck,setLoading) =>
  async (dispatch) => {
    try {
      setLoading(true);
      let path;
      if (searchQuery) {
        path = `job_openings/todays_onboarding?query=${searchQuery}`;
      }
      else if (clickCheck?.length > 0) {
        const idArr = clickCheck?.filter((item) => item.id !== null)?.map(item => item.id)
        path = `job_openings/todays_onboarding?handled_by=${idArr}`;
      }
      else {
        path = `job_openings/todays_onboarding`
      }
      const { response } = await Helper.get(path);
      const formatedOnboard = formatOnboardToday(
        response ? response : []
      );
      dispatch(setDashboardOnboardToday(formatedOnboard))
      setLoading(false);

    } catch (error) {
      ToastServices.showToast({
        message: "No Activity Found",
        type: "error",
      });
    }
  }

export const dashboardGraph = () => async (dispatch) => {
  try {
    const path = `job_openings/opening_summary`;
    const { response } = await Helper.get(path);
    const formatedGraphData = formatGraphData(response ? response : []);
    dispatch(setGraphData(formatedGraphData))

  } catch (error) {
    // setLoder(false);
    ToastServices.showToast({
      message: "No Activity Found",
      type: "error",
    });
  }
};
