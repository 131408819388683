import { useOnClickOutside } from "component/common/useOneClickOutsid";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { MdRefresh } from "react-icons/md";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { handleSearchUserForChat } from "redux/appThunk/Employee/chat";
import useDebounce from "hooks/useDebounce";
import { setCheckData } from "reduxToolkit/JobSlice";
import Chips from "./Chips";
import { useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

const FilterColumn = ({
  newClass,
  filterOptions,
  filterState,
  disabledFilterState,
  showFilter,
  onClose,
  onChange,
  onSave,
  onRefresh,
  activeItemId,
  setActiveItemId
}) => {
  const { t } = useTranslation();
  const { searchResult } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.includes('/applicants');
  const [guestName, setGuestName] = useState("");
  const [applySearch, setApplySearch] = useState("");
  let searchQuery = useDebounce(guestName, 300);
  const [handleByOpen, setHandleByOpen] = useState(false);
  const filterRef = useRef();
  const { getCheckData, getAllOpeningsData } = useSelector((state) => state.jobs);
  const [checkItem, setCheckItem] = useState(
    getCheckData?.length > 0 ? getCheckData : []
  );

  useOnClickOutside(filterRef, () => {
    onClose();
    setActiveItemId(false);
  });


  const openingData = getAllOpeningsData?.map((item) => item?.title);

  const handleOpen = (id) => {
    if (id === '1') {
      setActiveItemId(!activeItemId)
      setHandleByOpen(false);
    } else if (id === '2') {
      setHandleByOpen(!handleByOpen)
      setActiveItemId(false)
    }
  };

  const handleSelect = (data) => {
    setCheckItem((prev) => {
      const checkData = prev?.find(item => item?.name === data?.name)
      if (checkData) {
        return prev?.filter(item => item.name !== data.name);
      } else {
        return [...prev, data]
      }
    })
  }

  const handleReset = () => {
    onRefresh();
    setCheckItem([]);
  };

  useEffect(() => {
    dispatch(setCheckData(checkItem));
  }, [checkItem, dispatch]);
  useEffect(() => {
    if (guestName !== "") {
      dispatch(handleSearchUserForChat(searchQuery));
    }
  }, [searchQuery]);

  const appliedType = openingData?.filter((item) =>
    item?.toLowerCase().includes(applySearch.toLowerCase())
  );
  const newType = applySearch ? appliedType : openingData;

  return (
    showFilter && (
      <div
        ref={filterRef}
        className={`flex flex-col gap-2 max-h-[43rem] w-[16.625rem] bg-white ${newClass}
            shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] z-30 border-[1px] rounded-md border-[#E2E8F0] animate-popover`}
      >
        <div className="flex justify-between items-center py-2 pt-4 px-3">
          <h1 className="text-[#002169] font-bold text-lg">{t("filters")}</h1>
          <div className="" onClick={() => onClose()}>
            <RxCross1 />
          </div>
        </div>
        <hr />
        {getCheckData?.length > 0 && (<div className="px-4">
          <div className="w-full h-full flex flex-wrap gap-1">
            {getCheckData?.map((item) => {
              return <Chips item={item} handleRemove={handleSelect} />;
            })}
          </div>
        </div>)}
        {/* upper section of jobOpening column filter */}
        <div className="flex h-fit flex-col gap-2 p-2">
          <div>
            {path && (<div
              className="border-2 rounded-md p-2 flex justify-between items-center cursor-pointer"
              onClick={() => handleOpen("1")}
            >
              <h1>{t("appliedFor")}</h1>
              <div>
                <BsChevronDown />
              </div>
            </div>)}
          </div>

          {activeItemId && (
            <>
              <div className="h-fit flex flex-col gap-2 p-2 border-2 rounded-md ">
                <div className="w-full border rounded-[0.5rem] flex flex-col gap-2">
                  <input className=" w-full
            h-[2.5rem] p-2  rounded-[0.5rem]"
                    placeholder="Search..."
                    value={applySearch}
                    onChange={(e) => setApplySearch(e.target.value)}
                  />
                </div>
                <div className=" h-[7rem] overflow-y-auto custom_scroll flex flex-col gap-[0.7rem]">
                  {newType?.length > 0 ?
                    (newType?.map((data) => {
                      return (
                        <div className="flex space-x-3 items-center w-[206px]" >
                          <div className="h-[20px] w-[20px]">
                            <input
                              className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                              type="checkbox"
                              checked={
                                checkItem?.find((item) => item.name === data)
                                  ? true
                                  : false
                              }
                              onChange={() =>
                                handleSelect({ name: data, id: null })
                              }
                            />
                          </div>
                          <div className="whitespace-normal break-words">{data}</div>
                        </div>
                      );
                    })
                    ) : (
                      <div> {t("noDataFound")}</div>
                    )}
                </div>
              </div>
            </>
          )}
          <div className='border-2 rounded-md p-2 flex justify-between items-center cursor-pointer' onClick={() => handleOpen('2')}>
            <h1>{t("handledBy")}</h1>
            <div>
              <BsChevronDown />
            </div>
          </div>
          {
            handleByOpen && (
              <div className="p-2 border-2 rounded-md">
                <div className="w-full border rounded-[0.5rem]">
                  <input value={guestName}
                    className=" w-full
                  h-[2.5rem] p-2 rounded-[0.5rem]"
                    placeholder="Search..." type="text"
                    onChange={(e) => setGuestName(e.target.value)}
                  />
                </div>
                {
                  guestName !== "" && (
                    <div className="h-[7rem] overflow-y-auto custom_scroll">
                      {searchResult.length > 0 ?
                        (searchResult.map((employee) => (
                          <div
                            key={employee.id}
                            className="flex gap-2 items-center
                 text-black h-[2rem] px-2 cursor-pointer hover:bg-gray-100"
                          >
                            <input
                              className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                              type="checkbox"
                              checked={checkItem?.find(item => item.name === employee.name) ? true : false}
                              onChange={() => handleSelect({ name: employee?.name, id: employee?.id })}
                            />

                            <p>{employee.name}</p>
                          </div>
                        ))) : (
                          <div> {t("noDataFound")}</div>
                        )
                      }
                    </div>
                  )
                }

              </div>
            )
          }
        </div>
        <div className="px-3">
          <h1 className="text-[#002169] font-bold text-lg">{t("columnFilter")}</h1>
        </div>
        {/* column filter */}
        <div className="max-h-[17rem] overflow-y-scroll custom_scroll w-full flex flex-col gap-[0.8rem] p-3">
          {filterOptions?.map((item, index) => (
            <div
              key={index}
              className="max-h-[2.25rem] w-[13.313rem] space-x-3 flex items-center"
            >
              <input
                className="rounded-[6px] focus:ring-transparent accent-[#031B59]
                    checked:bg-[#031B59] h-[20px] w-[20px]"
                id={item.key}
                type="checkbox"
                disabled={disabledFilterState.includes(item.key)}
                checked={filterState[item.key] ?? false}
                onChange={onChange}
              />
              <span
                className={`${filterState[item.key] ? "text-[#031B59]" : "text-[#191919]"
                  }`}
              >
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className="w-full h-full flex items-center justify-between space-x-3 bg-transparent px-3 py-2">
          <button
            className="h-[3.063rem] w-full basis-1/3 flex items-center justify-center border-[1.5px]
                rounded-md enabled:border-[#031B59] enabled:text-[#031B59] disabled:border-[#A1A1A1]
                disabled:text-[#A1A1A1] disabled:opacity-80"
            type="submit"
            onClick={handleReset}
          >
            <MdRefresh className="h-5 w-5 flex items-center justify-center" />
          </button>
          <button
            className="h-[3.063rem] basis-2/3 p-2 border-[1.5px]  enabled:border-[#031B59] enabled:text-[#031B59]
                disabled:border-[#A1A1A1] disabled:text-[#A1A1A1] disabled:opacity-80 font-medium rounded-md"
            type="submit"
            onClick={onSave}
          >
            {t("save")}
          </button>
        </div>
      </div>
    )
  );
};

FilterColumn.propTypes = {
  filterOptions: PropTypes.array,
  filterState: PropTypes.object,
  disabledFilterState: PropTypes.array,
  showFilter: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default FilterColumn;
