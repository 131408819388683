import React, { useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { RxCross2 } from "react-icons/rx";
import ScheduleEventPopUp from "./ScheduleEventPopUp";
import ClientInterviewPopUp from "./ClientInterviewPopUp";
import CandidateInterviewPopUp from "./CandidateInterviewPopUp";
const EventTypeDropdown = () => {
  const [selectedEvent, setSelectedEvent] = useState("Schedule");
  const [guests, setGuests] = useState([]);
  const [file, setFile] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Schedule");

  const toggleDropdown = () => setIsOpen(!isOpen);
  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Handle event change
  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  return (
    <div className="w-full max-w-[35rem] pl-4 mt-10 overflow-y-scroll">
      <label
        htmlFor="eventType"
        className="block text-sm font-medium text-gray-700 pl-4"
      >
        Event Type
      </label>
      <div className="relative inline-block w-[31.8rem] ml-4">
      <div
        className="bg-white border border-gray-300 rounded-md px-4 py-2 cursor-pointer"
        onClick={toggleDropdown}
      >
        <span>{selectedOption}</span>
        <svg
          className={`w-5 h-5 inline-block float-right transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      {isOpen && (
        <ul className="absolute w-full bg-white border border-gray-300 rounded-md mt-1 shadow-lg z-10">
          {["Schedule", "Client Interview", "Candidate Interview"].map((option) => (
            <li
              key={option}
              className={`px-4 py-2 cursor-pointer hover:bg-[#F2F6FF] ${
                selectedOption === option ? "bg-[#F2F6FF]" : ""
              }`}
              onClick={() => {selectOption(option);setSelectedEvent(option);}}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>

      {/* Conditional rendering based on selected event */}
      <div className="mt-4">
        {/* Schedule event */}
        {selectedEvent === "Schedule" && (
          <ScheduleEventPopUp/>
        )}

        {/* Client Interview */}
        {selectedEvent === "Client Interview" && (
       <ClientInterviewPopUp/>
        )}

        {/* Candidate Interview */}
        {selectedEvent === "Candidate Interview" && (
          <CandidateInterviewPopUp/>
        )}
      </div>
    </div>
  );
};

export default EventTypeDropdown;
