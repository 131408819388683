import CustomTable from "component/common/table/CustomTable";
import JobApplicantAction from "component/jobOpening/JobApplicantAction";
import { useDispatch, useSelector } from "react-redux";
import { clickData, dashboardOnboardToday, setjobApplication } from "reduxToolkit/JobSlice";
import upDownArrow from "svgComponents/upDownArrow";
import AddResourcessss from "../jobOpening/AddResourceModal"
import OnBoardDate from "component/jobOpening/OnBoardDate";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Search from "component/common/accessControlUi/Search";
import { IoFilter } from "react-icons/io5";
import usePermissions, { mappedPermissionObj } from "hooks/usePermission";
import { ClickOutsideClose } from "component/common/ClickOutsideClose";
import FilterColumn from "component/common/FilterColumn";
import { disabledFilterState, filterOptions } from "utils/Constants";

const { default: AddResource } = require("component/jobOpening/AddResource");
const { useState, useRef, useEffect } = require("react");
const { GoPlusCircle } = require("react-icons/go");


const ViewAllPopUp = ({ setOpenViewAllPopUp, openViewAllPopUp }) => {
  let alignValues = {
    left: "left",
    right: "right",
    center: "center",
  };
  const { userPermission } = usePermissions(mappedPermissionObj.Jobopening);
  const [addResource, setAddResource] = useState(null);
  const [isClickedForPop, setIsClickedForPop] = useState(true);
  const [showDropdown, setShowDropdown] = useState("");
  const [clickCheck, setClickCheck] = useState([]);
  const [status, setStatus] = useState("applied");
  const [loading, setLoading] = useState(false);
  const [againgetData, setAgainData] = useState(false);
  const [jobDetails, setJobDetails] = useState()
  const [onBoardPop, setOnBoardPop] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const menuRef = useRef(null);
  const { getAllApplicantsData, getCheckData } = useSelector((state) => state.jobs);
  const [openFilter, setOpenFilter] = useState(false);
  const [activeItemId, setActiveItemId] = useState(false);
  const { allDashboardOnboardToday } = useSelector((state) => state.jobs);
  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );
  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState?.map((key) => ({ [key]: true })))
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCloseAddResource = () => {
    setAddResource(null);
  };
  const handleOnBorad = (job, jobIndex) => {
    setJobDetails(job);
    setOnBoardPop(true);
  }

  useEffect(() => {
    dispatch(dashboardOnboardToday(searchQuery, clickCheck,setLoading))
  }, [searchQuery, clickCheck])


  const handleDropDownClick = (id) => {
    if (showDropdown === id) {
      setShowDropdown("");
    } else {
      setShowDropdown(id);
    }
    dispatch(setjobApplication(id));
  };

  const handleClosePopUp = () => {
    setOpenViewAllPopUp(false)
  }

  const handleOpenFilter = () => {
    if (isClickedForPop) {
      setOpenFilter(!openFilter);
      setIsClickedForPop(false);
    }
  }

  const handleFilterChange = (e) => {
    const { id, checked } = e.target;
    setFilters((prev) => {
      if (checked) {
        return { ...prev, [id]: true };
      } else {
        const { [id]: removedFilter, ...rest } = prev;

        return rest;
      }
    });
  };
  useEffect(() => {
    dispatch(clickData(clickCheck))
  }, [clickCheck])

  const handleSaveFilters = () => {
    setClickCheck(getCheckData);
    setColumnsFilters((prev) => {
      if (!Object.keys(filters)) {
        return new Set([...prev]);
      } else {
        return new Set([...Object.keys(filters)])

      }
    });
    setOpenFilter(false);
  };

  const handleRefresh = () => {
    setClickCheck([]);
    setFilters(() =>
      Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
    );
    setColumnsFilters(() => new Set(disabledFilterState));
  };


  const jobApplicantColumSelected = [
    {
      title: "Name",
      field: "jobFullName",
      align: alignValues.left,
      render: (fieldValue) => fieldValue,
      className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
      sorting: true,
    },
    {
      title: "Applied For",
      field: "appliedFor",
      icon: upDownArrow,
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Onboarding Date",
      icon: upDownArrow,
      field: "appliedDate",
      align: alignValues.left,
      render: (value, job, jobIndex) => {
        return (
          (job?.onBoardDate ? (
            job?.onBoardDate
          ) : (
            <div className='flex gap-2 items-center text-[#031b59] cursor-pointer underline'
              onClick={() => handleOnBorad(job, jobIndex)}
            >
              <GoPlusCircle />
              <h1>{t('setDate')}</h1>
            </div>
          ))
        )
      },
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Handled By",
      field: "resources",
      align: alignValues.left,
      render: (values, job, jobIndex) => {
        return (
          <>
            <AddResource
              setAddResource={setAddResource}
              resourceData={job?.handledByNew}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              currentIndex={jobIndex}
              data={job?.handledByNew?.map((item) => item)}
              id={job?.id}
              onDropdownClick={() => handleDropDownClick(job?.id)}
              status={status}
              setLoading={setLoading}
              setAgainData={setAgainData}

            />
          </>
        );

      },
      className: "min-w-[10rem] justify-center p-2",
      style: "",

    },
    {
      title: "Location",
      field: "state",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.state !== null ? job?.state : "--"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Experience",
      field: "experience",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.experience !== null ? job?.experience : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

  ];


  ClickOutsideClose(openFilter, setIsClickedForPop, menuRef, () => setShowDropdown(""))

  const array = [];
  columnFilters.forEach(v => array.push(v));
  const filteredObjectsAllTab3 = jobApplicantColumSelected?.filter(obj => array?.includes(obj?.field));

  return (
    <>
      <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
        <div className="min-w-[69%] w-full max-w-md h-full bg-white flex flex-col
           space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all">
          <div className=" w-full h-[5rem] px-[8.8] flex justify-between items-center border-b border-[#E2E8F0]">
            <div className="w-full h-[42px] items-center bg-white flex justify-between p-7">
              <div className="flex justify-center items-center">
                <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                  {t("onboardCandidate")}
                </h2>
              </div>
              <div
                className="text-[1.5rem] cursor-pointer mr-[-.64rem]"
                onClick={handleClosePopUp}
              >
                <RxCross2 />
              </div>
            </div>
          </div>
          <div className='flex justify-end gap-3 w-full px-7'>
            <button>
              <Search searchItem={searchQuery} setSearchItem={setSearchQuery}
                currentResource={userPermission}
              />
            </button>
            <div className='cursor-pointer border p-2 rounded-[50%]' onClick={handleOpenFilter}>
              <IoFilter
                className="w-6 h-6 stroke-[#031B59]"
              />
            </div>
          </div>
          <div className="px-7">
            <CustomTable
              data={allDashboardOnboardToday?.data}
              columns={filteredObjectsAllTab3}
              isLoading={loading}
              renderAction={(
                job,
                jobIndex,
                dropdownRef
              ) => {
                return (
                  <>

                    {onBoardPop && <OnBoardDate setOnBoardPop={setOnBoardPop} jobDetails={jobDetails} setAgainData={setAgainData} />}
                    {addResource && (<AddResourcessss setAgainData={setAgainData}
                      id={addResource} handleClose={handleCloseAddResource} status={status}
                      setLoading={setLoading} setAddResource={setAddResource} />)}
                    <JobApplicantAction
                      data={getAllApplicantsData?.data}
                      job={job} jobIndex={jobIndex} dropdownRef={dropdownRef}
                      againgetData={againgetData}
                      setAgainData={setAgainData} />
                  </>
                );
              }}
            />
          </div>
        </div>
        {openFilter && <FilterColumn
            data={dashboardOnboardToday?.data}
            newClass={`absolute ${true ? "top-[10.5rem]" : "top-[14.7rem]"}  right-[2rem]`}
            showFilter={openFilter}
            filterOptions={filterOptions}
            disabledFilterState={disabledFilterState}
            filterState={filters}
            onChange={handleFilterChange}
            onSave={handleSaveFilters}
            onClose={() => {
              setOpenFilter(false);
            }}
            onRefresh={handleRefresh}
            setActiveItemId={setActiveItemId}
            activeItemId={activeItemId}
          />
        
      }
      </div >
    </>
  )
}
export default ViewAllPopUp