/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */


import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { JobApplicantsHeader, disabledFilterState, filterOptions } from 'utils/Constants';
import { Link, useLocation } from "react-router-dom";
import CustomTable from 'component/common/table/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import JobApplicantAction from './JobApplicantAction';
import UseFetchJobApplicantsData from './UseFetchJobApplicantsData';
import Search from 'component/common/accessControlUi/Search';
import usePermissions, { mappedPermissionObj } from 'hooks/usePermission';
import upDownArrow from "svgComponents/upDownArrow";
import AddResourcessss from './AddResourceModal';
import PropTypes from "prop-types";
import { RxCross2 } from 'react-icons/rx';
import UseFetchJobSection from './UseFetchJobSection';
import AddResource from './AddResource';
import { setjobApplication } from 'redux/actions/action';
import Paginate from 'component/Employee/Paginate';
import { IoFilter } from 'react-icons/io5';
import FilterColumn from 'component/common/FilterColumn';
import { fetchAllJobOpeningForAppliedFor, setCheckData, updateDropdownStatus } from 'reduxToolkit/JobSlice';
import { IoIosArrowDown } from 'react-icons/io';
import { ClickOutsideClose } from 'component/common/ClickOutsideClose';
import { GoPlusCircle } from 'react-icons/go';
import OnBoardDate from './OnBoardDate';

const JobApplicants = ({ setopenApplications }) => {
  const { userPermission } = usePermissions(mappedPermissionObj.Jobopening);
  const [tabValue, setTabValue] = useState(1);
  const [isClickedForPop, setIsClickedForPop] = useState(true);
  const [status, setStatus] = useState("applied");
  const [openFilter, setOpenFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [againgetData, setAgainData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [clickCheck, setClickCheck] = useState([]);
  const { getAllApplicantsData, jobApplications, getCheckData } = useSelector((state) => state.jobs);
  const dispatch = useDispatch();
  let { data, totalPages } = getAllApplicantsData;
  const [selectedCheckbox, setSelectedCheckboxes] = useState({});
  const { GetJobID } = useSelector((state) => state.jobs);
  const location = useLocation();
  const path = location.pathname.includes('currentOpening');
  const [addResource, setAddResource] = useState(null);
  const [showDropdown, setShowDropdown] = useState("");
  const { t } = useTranslation();
  const [activeItemId, setActiveItemId] = useState(false);
  const [columnFilters, setColumnsFilters] = useState(
    () => new Set(disabledFilterState)
  );
  const [itemsPagination, setItemPagination] = useState(10);
  const [onBoardPop, setOnBoardPop] = useState(false);
  const [tryStatus, setTryStatus] = useState(false);
  const menuRef = useRef(null);
  const [filters, setFilters] = useState(
    Object.assign({}, ...disabledFilterState?.map((key) => ({ [key]: true })))
  );

  const [jobDetails, setJobDetails] = useState();

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "long", year: "numeric" };

    return date.toLocaleDateString("en-GB", options);
  };
  let alignValues = {
    left: "left",
    right: "right",
    center: "center",
  };


  function changeObjectToArray(data) {
    return Object.values(data).reduce((acc, obj) => {
      const keys = Object.keys(obj).filter(key => obj[key]);

      return acc.concat(keys.map(Number));
    }, []);
  }

  const callback = (value) => {
    setLoading(value);
  };

  const handleRefresh = () => {
    setClickCheck([]);
    setFilters(() =>
      Object.assign({}, ...disabledFilterState.map((key) => ({ [key]: true })))
    );
    setColumnsFilters(() => new Set(disabledFilterState));
  };


  useEffect(() => {
    if (activeItemId) {
      dispatch(fetchAllJobOpeningForAppliedFor())
    }
  }, [activeItemId])

  const handleFilterChange = (e) => {
    const { id, checked } = e.target;
    setFilters((prev) => {
      if (checked) {
        return { ...prev, [id]: true };
      } else {
        const { [id]: removedFilter, ...rest } = prev;

        return rest;
      }
    });
  };

  const handleMove = async (val) => {
    const data = changeObjectToArray(selectedCheckbox);
    const jsonObj = {
      status: val,
      job_application_ids: [...data],
    };
    await dispatch(updateDropdownStatus(jsonObj, setAgainData));
    setSelectedCheckboxes({});
  };
  {
    path ? (
      UseFetchJobSection({
        clickCheck: clickCheck,
        status: status,
        id: GetJobID?.id,
        callback: callback,
        againgetData: againgetData,
        query: searchQuery,
        newId: GetJobID,
        permissionToFetch: userPermission,
      })
    ) : (
      UseFetchJobApplicantsData({
        clickCheck: clickCheck,
        permissionToFetch: userPermission,
        query: searchQuery,
        currentPage: currentPage,
        status: status,
        setLoading: setLoading,
        againgetData: againgetData,
        itemsPagination: itemsPagination
      })
    );
  }

  const handleOpenFilter = () => {
    if (isClickedForPop) {
      setOpenFilter(true);
      setIsClickedForPop(false);
    }
  }

  ClickOutsideClose(openFilter, setIsClickedForPop, menuRef, () => setShowDropdown(""))

  const handleDropDownClick = (id) => {
    if (showDropdown === id) {
      setShowDropdown("");
    } else {
      setShowDropdown(id);
    }
    dispatch(setjobApplication(id));
  };

  useEffect(() => {
    dispatch(setCheckData([]));
  }, [])

  const handleSaveFilters = () => {
    setClickCheck(getCheckData);
    setColumnsFilters((prev) => {
      if (!Object.keys(filters)) {
        return new Set([...prev]);
      } else {
        return new Set([...Object.keys(filters)])

      }
    });
    setOpenFilter(false);
  };

  const handleOnBorad = (job, jobIndex) => {
    setJobDetails(job);
    setOnBoardPop(true);
  }

  const jobApplicantColum = [
    {
      title: "Name",
      field: "jobFullName",
      inputcheck: "checkbox",
      align: alignValues.left,
      render: (fieldValue) => fieldValue,
      className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
      sorting: true,
    },
    {
      title: "Handled By",
      field: "resources",
      align: alignValues.left,
      render: (values, job, jobIndex) => {
        return (
          <>
            <AddResource
              setAddResource={setAddResource}
              resourceData={job?.handledByNew}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              currentIndex={jobIndex}
              data={job?.handledByNew?.map((item) => item)}
              id={job?.id}
              onDropdownClick={() => handleDropDownClick(job?.id)}
              status={status}
              currentPage={currentPage}
              setLoading={setLoading}
              setAgainData={setAgainData}

            />
          </>
        );

      },
      className: "min-w-[10rem] justify-center p-2",
      style: "",

    },
    {
      title: "Applied For",
      field: "appliedFor",
      icon: upDownArrow,
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Applied Date",
      icon: upDownArrow,
      field: "appliedDate",
      align: alignValues.left,
      render: (value) => formatDate(value),
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Location",
      field: "state",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.state !== null ? job?.state : "--"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Experience",
      field: "experience",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.experience !== null ? job?.experience : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "CCTC",
      field: "currentCTC",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.currentCTC !== null ? job?.currentCTC : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "ECTC",
      field: "expectedSalary",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.expectedSalary !== null ? job?.expectedSalary : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "NP",
      field: "noticePeriod",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.noticePeriod !== null ? job?.noticePeriod : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

  ];
  const jobApplicantColumSelected = [
    {
      title: "Name",
      field: "jobFullName",
      inputcheck: "checkbox",
      align: alignValues.left,
      render: (fieldValue) => fieldValue,
      className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
      sorting: true,
    },
    {
      title: "Handled By",
      field: "resources",
      align: alignValues.left,
      render: (values, job, jobIndex) => {
        return (
          <>
            <AddResource
              setAddResource={setAddResource}
              resourceData={job?.handledByNew}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              currentIndex={jobIndex}
              data={job?.handledByNew?.map((item) => item)}
              id={job?.id}
              onDropdownClick={() => handleDropDownClick(job?.id)}
              status={status}
              currentPage={currentPage}
              setLoading={setLoading}
              setAgainData={setAgainData}

            />
          </>
        );

      },
      className: "min-w-[10rem] justify-center p-2",
      style: "",

    },
    {
      title: "Applied For",
      field: "appliedFor",
      icon: upDownArrow,
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Applied Date",
      icon: upDownArrow,
      field: "appliedDate",
      align: alignValues.left,
      render: (value) => formatDate(value),
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Onboarding Date",
      icon: upDownArrow,
      field: "appliedDate",
      align: alignValues.left,
      render: (value, job, jobIndex) => {
        return (
          (job?.onBoardDate ? (
            job?.onBoardDate
          ) : (
            <div className='flex gap-2 items-center text-[#031b59] cursor-pointer underline'
              onClick={() => handleOnBorad(job, jobIndex)}
            >
              <GoPlusCircle />
              <h1>{t('setDate')}</h1>
            </div>
          ))
        )
      },
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Location",
      field: "state",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.state !== null ? job?.state : "--"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Experience",
      field: "experience",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.experience !== null ? job?.experience : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "CCTC",
      field: "currentCTC",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.currentCTC !== null ? job?.currentCTC : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "ECTC",
      field: "expectedSalary",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.expectedSalary !== null ? job?.expectedSalary : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "NP",
      field: "noticePeriod",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.noticePeriod !== null ? job?.noticePeriod : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

  ];
  const jobApplicantColumInprogress = [
    {
      title: "Name",
      field: "jobFullName",
      inputcheck: "checkbox",
      align: alignValues.left,
      render: (fieldValue) => fieldValue,
      className: "min-w-[10rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
      sorting: true,
    },
    {
      title: "Handled By",
      field: "resources",
      align: alignValues.left,
      render: (values, job, jobIndex) => {
        return (
          <>
            <AddResource
              setAddResource={setAddResource}
              resourceData={job?.handledByNew}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
              currentIndex={jobIndex}
              data={job?.handledByNew?.map((item) => item)}
              id={job?.id}
              onDropdownClick={() => handleDropDownClick(job?.id)}
              status={status}
              currentPage={currentPage}
              setLoading={setLoading}
              setAgainData={setAgainData}

            />
          </>
        );

      },
      className: "min-w-[10rem] justify-center p-2",
      style: "",

    },
    {
      title: "Applied For",
      field: "appliedFor",
      icon: upDownArrow,
      align: alignValues.left,
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Applied Date",
      icon: upDownArrow,
      field: "appliedDate",
      align: alignValues.left,
      render: (value) => formatDate(value),
      className: "min-w-[11rem] w-fit px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Location",
      field: "state",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.state !== null ? job?.state : "--"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868]",
      style: "",
    },
    {
      title: "Experience",
      field: "experience",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.experience !== null ? job?.experience : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "CCTC",
      field: "currentCTC",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.currentCTC !== null ? job?.currentCTC : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "ECTC",
      field: "expectedSalary",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer uppercase">
            {job?.expectedSalary !== null ? job?.expectedSalary : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] uppercase",
      style: "",
    },
    {
      title: "NP",
      field: "noticePeriod",
      align: alignValues.left,
      render: (value, job) => {
        return (
          <div className="flex gap-1 items-center cursor-pointer capitalize">
            {job?.noticePeriod !== null ? job?.noticePeriod : "---"}
          </div>
        );
      },
      className: "min-w-[100px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },
    {
      title: "Round",
      field: "round",
      align: alignValues.left,
      render: (values, job) => {
        return (
          <div className={`${job?.round ? null : "text-[#031b59]"}`}
          >{job?.round ? (
            <div className='text-[#ED6E0F]
            '> {tabValue === 2 ? (
                <>
                  {job?.feedbackExist ? (
                    <div className='text-[#1A8718]'>
                      {job?.round}
                    </div>
                  ) : (
                    <>
                      {t("interView")} ({job?.round})
                    </>
                  )}
                </>
              ) : (
                <>
                  {t("interViewed")} ({job?.round})
                </>
              )}</div>
          ) : (t('Schedule'))}</div>
        );
      },
      className: "min-w-[60px] px-[15px] h-[50px] text-[#686868] capitalize",
      style: "",
    },

  ];

  const array = [];
  columnFilters.forEach(v => array.push(v));
  const filteredObjectsAllTab1 = jobApplicantColum.filter(obj => array?.includes(obj?.field));
  const filteredObjectsAllTab2 = jobApplicantColumInprogress.filter(obj => array?.includes(obj?.field));
  const filteredObjectsAllTab3 = jobApplicantColumSelected?.filter(obj => array?.includes(obj?.field));

  const handleSelectAll = (checked, tableData) => {
    if (checked) {
      const converteddata = tableData.reduce((acc, ele) => {
        return { ...acc, [ele.id]: true };
      }, {});
      setSelectedCheckboxes({ ...selectedCheckbox, [currentPage]: { ...converteddata } });
    }
    else {
      let currentObj = { ...selectedCheckbox };
      delete currentObj[currentPage];
      setSelectedCheckboxes({ ...currentObj });
    }
  };

  const handleStatusChange = () => {
    setTryStatus(!tryStatus)
  }
  
  const handleSelectRow = (id, checked) => {
    setSelectedCheckboxes((prev) => {
      let copiedBoxes = { ...prev };

      if (checked) {
        copiedBoxes[currentPage] = { ...copiedBoxes[currentPage], [id]: true };

        return { ...copiedBoxes, [currentPage]: { ...copiedBoxes[currentPage] } };
      }
      else {
        let currentObj = { ...copiedBoxes[currentPage] };
        delete currentObj[id];
        if (Object.keys(currentObj).length === 0) {
          delete copiedBoxes[currentPage];

          return { ...copiedBoxes };
        }

        return { ...copiedBoxes, [currentPage]: { ...currentObj } };
      }
    });
  };
  const handleCloseAddResource = () => {
    setAddResource(null);
  };

  const tabChange = (obj) => {
    setTabValue(obj.id),
      setStatus(obj.status);
    setCurrentPage(1);
    setSelectedCheckboxes({});
  }



  return (
    <>
      {onBoardPop && <OnBoardDate setOnBoardPop={setOnBoardPop} jobDetails={jobDetails} setAgainData={setAgainData} />}
      {addResource && (<AddResourcessss setAgainData={setAgainData}
        id={addResource} handleClose={handleCloseAddResource} status={status}
        currentPage={currentPage}
        setLoading={setLoading} setAddResource={setAddResource} />)}
      {
        path ? (
          <>

            <div className='h-[95vh] flex flex-col gap-5'>
              <div className="w-full h-[42px] bg-white flex justify-between">
                <div className="flex justify-between w-full items-center">
                  <h2 className="font-bold text-xl text-[#031B59]">
                    {t("applicants")}
                  </h2>
                  <div
                    className="border-2 p-1 text-[1.5rem] cursor-pointer"
                    onClick={() => setopenApplications(false)}
                  >
                    <RxCross2 />
                  </div>
                </div>
              </div>
              <div className='flex justify-between h-fit border-b-2'>
                {
                  JobApplicantsHeader.map((obj) => {
                    return (
                      <button key={obj.id} className={`  text-xl w-[15rem] p-4
              ${tabValue === obj.id
                          ? "text-[#002169] font-bold underline-large"
                          : "text-[#686868]"
                        }`}>
                        <Link rel="stylesheet" href="#" onClick={() => {
                          setTabValue(obj.id),
                            setStatus(obj.status);
                          setCurrentPage(1);
                          setSelectedCheckboxes({});
                        }} className='flex justify-center
                items-center gap-1'>
                          <div className={` ${tabValue === obj.id
                            ? "active_status"
                            : "text-[#686868]"
                            }`}>
                            <obj.icon />
                          </div>
                          <h5
                          >
                            {t(obj.headName)} ({jobApplications?.[obj.count] ? jobApplications?.[obj.count] : 0})
                          </h5>
                        </Link>
                      </button>
                    );
                  })
                }
              </div>
              <div className='flex items-center'>
                <div className=''>

                  {Object.keys(selectedCheckbox).length > 0 && (
                    <div onClick={handleStatusChange}>
                      <div className='w-[100px] text-[#686868] p-2 rounded-[0.6rem]
                 bg-white border border-[#DEE4EB] flex items-center justify-around cursor-pointer'> {t("Status")}

                        <IoIosArrowDown />
                      </div>
                      {
                        tryStatus && (
                          <div className='absolute z-30 bg-white w-[10rem] flex flex-col border border-[#DEE4EB] rounded-[0.6rem] '>
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 5) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('inprogress')} value={"inprogress"}>{t("Inprogress")}</p>
                              )
                            }
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 2 || tabValue === 5) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('selected')} value={"selected"}>{t("Selected")}</p>
                              )
                            }{
                              (tabValue === 1 || tabValue === 5 || tabValue === 2 || tabValue === 3) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('onhold')} value={"onhold"}>{t("Onhold")}</p>
                              )
                            }
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 2 || tabValue === 3) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('rejected')} value={"rejected"}>{t("Rejected")}</p>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  )}
                </div>
                <div className='flex justify-end gap-3 w-full'>
                  <button>
                    <Search searchItem={searchQuery} setSearchItem={setSearchQuery}
                      currentResource={userPermission}
                    />
                  </button>
                  <div className='cursor-pointer border p-2 rounded-[50%]' onClick={handleOpenFilter}>
                    <IoFilter
                      className="w-6 h-6 stroke-[#031B59]"

                    />
                  </div>
                </div>
              </div>
              <div className='h-full custom_scroll overflow-y-scroll'>
                <CustomTable
                  data={jobApplications?.requiredResponse}
                  columns={tabValue === 2 ? filteredObjectsAllTab2 : (tabValue === 3 ? filteredObjectsAllTab3 : filteredObjectsAllTab1)}
                  isLoading={loading}
                  selectedItems={selectedCheckbox?.[currentPage] && selectedCheckbox[currentPage]}
                  setSelectedItems={handleSelectRow}
                  allSelect={selectedCheckbox?.[currentPage] && Object.keys(selectedCheckbox?.[currentPage]).length
                    === jobApplications?.requiredResponse.length}
                  handleSelectAll={handleSelectAll}
                  renderAction={(
                    job,
                    jobIndex,
                    dropdownRef
                  ) => {
                    return (
                      <>
                        <JobApplicantAction tabValue={tabValue}
                          data={data}
                          job={job} jobIndex={jobIndex} dropdownRef={dropdownRef}
                          againgetData={againgetData}
                          setAgainData={setAgainData} />
                      </>
                    );
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-full h-[42px] bg-white flex justify-between py-[2.8rem] px-[2.3rem]">
              <div className="flex justify-center items-center">
                <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                  {t("applicants")}
                </h2>
              </div>
            </div>
            <div
              className="w-[96%]  h-[calc(96vh-5.1rem)] bg-white space-y-4 flex flex-col p-5
        rounded-xl shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)]"
            >

              <div className='flex justify-between h-fit border-b-2'>
                {
                  JobApplicantsHeader.map((obj) => {
                    return (
                      <button key={obj.id} className={`text-xl w-[15rem] p-4
                ${tabValue === obj.id
                          ? "text-[#002169] font-bold underline-large"
                          : "text-[#686868]"
                        }`}>
                        <Link rel="stylesheet" href="#" onClick={() => tabChange(obj)} className='flex justify-center
                  items-center gap-1'>
                          <div className={` ${tabValue === obj.id
                            ? "active_status"
                            : "text-[#686868]"
                            }`}>
                            <obj.icon />
                          </div>
                          <h5
                          >
                            {t(obj.headName)} ({getAllApplicantsData?.[obj.count] ? getAllApplicantsData?.[obj.count] : 0})
                          </h5>
                        </Link>
                      </button>
                    );
                  })
                }
              </div>
              <div className=' w-full flex items-center justify-between'>
                {Object.keys(selectedCheckbox).length > 0 ?
                  (
                    <div onClick={handleStatusChange}>
                      <div className='w-[100px] text-[#686868] p-2 rounded-[0.6rem]
                 bg-white border border-[#DEE4EB] flex items-center justify-around cursor-pointer'> {t("Status")}

                        <IoIosArrowDown />
                      </div>
                      {
                        tryStatus && (
                          <div className='absolute z-30 bg-white w-[10rem] flex flex-col border border-[#DEE4EB] rounded-[0.6rem] '>
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 5) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('inprogress')} value={"inprogress"}>{t("Inprogress")}</p>
                              )
                            }
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 2 || tabValue === 5) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('selected')} value={"selected"}>{t("Selected")}</p>
                              )
                            }{
                              (tabValue === 1 || tabValue === 5 || tabValue === 2 || tabValue === 3) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('onhold')} value={"onhold"}>{t("Onhold")}</p>
                              )
                            }
                            {
                              (tabValue === 1 || tabValue === 4 || tabValue === 2 || tabValue === 3) && (
                                <p className="w-full h-full rounded-lg pl-4 py-[7px] cursor-pointer hover:bg-[#F2F6FF]" onClick={() => handleMove('rejected')} value={"rejected"}>{t("Rejected")}</p>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <>
                      <div className='w-full text-[#031b59]'>
                        {data?.length} {t('listJob')}
                      </div>
                    </>
                  )
                }
                <div className='flex justify-end gap-3 w-full'>
                  <button>
                    <Search searchItem={searchQuery} setSearchItem={setSearchQuery}
                      currentResource={userPermission}
                    />
                  </button>
                  <div className='cursor-pointer border p-2 rounded-[50%]' onClick={handleOpenFilter}>
                    <IoFilter
                      className="w-6 h-6 stroke-[#031B59]"

                    />
                  </div>
                </div>

              </div>
              <div className='h-full custom_scroll overflow-y-scroll'>
                <CustomTable
                  data={data}
                  columns={tabValue === 2 ? filteredObjectsAllTab2 : (tabValue === 3 ? filteredObjectsAllTab3 : filteredObjectsAllTab1)}
                  isLoading={loading}
                  selectedItems={selectedCheckbox?.[currentPage] && selectedCheckbox[currentPage]}
                  setSelectedItems={handleSelectRow}
                  allSelect={selectedCheckbox?.[currentPage] && Object.keys(selectedCheckbox?.[currentPage]).length
                    === data.length}
                  handleSelectAll={handleSelectAll}
                  renderAction={(
                    job,
                    jobIndex,
                    dropdownRef
                  ) => {
                    return (
                      <>
                        <JobApplicantAction tabValue={tabValue}
                          data={getAllApplicantsData?.data}
                          job={job} jobIndex={jobIndex} dropdownRef={dropdownRef}
                          againgetData={againgetData}
                          setAgainData={setAgainData} />
                      </>
                    );
                  }}
                />
              </div>
              <div className='w-full flex items-end'>
                {
                  path ? null : (
                    <div className="w-full h-fit py-2 bg-white flex justify-between items-center">
                      <div className="text-[#031B59] font-medium">
                        {t("showing")} {currentPage} {t("of")} {totalPages}
                      </div>
                      <Paginate
                        currentPage={+currentPage}
                        initialPageCount={+totalPages}
                        pageRangeDisplayed={5}
                        setCurrentPage={setCurrentPage}
                        setItemPerPage={setItemPagination}
                        itemPerPage={itemsPagination}
                      />
                    </div>
                  )
                }
              </div>


            </div>
          </>
        )
      }
      {openFilter && (
        <FilterColumn
          data={data}
          newClass={`absolute ${path ? "top-[10.5rem]" : "top-[14.7rem]"}  right-[2rem]`}
          showFilter={openFilter}
          filterOptions={filterOptions}
          disabledFilterState={disabledFilterState}
          filterState={filters}
          onChange={handleFilterChange}
          onSave={handleSaveFilters}
          onClose={() => {
            setOpenFilter(false);
          }}
          onRefresh={handleRefresh}
          setActiveItemId={setActiveItemId}
          activeItemId={activeItemId}
        />
      )}
    </>

  );
};

export default JobApplicants;
JobApplicants.propTypes = {
  setopenApplications: PropTypes.any,
};
