import React from "react";
import { IoToggle } from "react-icons/io5";
import { SlCloudUpload } from "react-icons/sl";
const ClientInterviewPopUp = () => {
  return (
    <div className="p-4 pt-0 rounded-md flex flex-col gap-5">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Resource Name
        </label>
        <div className="flex flex-wrap gap-2 mt-1 border border-gray-300 p-1 rounded-md">
          <span className="flex gap-2 items-center px-2 py-1   rounded-md">
            <img
              src="https://via.placeholder.com/40"
              alt="profile"
              className="h-5 w-5 rounded-full"
            />
            <span className="mr-2">mitali</span>
          </span>
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Description
        </label>
        <textarea
          placeholder="Placeholder"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div className="flex justify-between items-center">
        <p>Budget</p>
        <span className="text-xl">
          <IoToggle />
        </span>
      </div>
      <div className="flex justify-between items-center">
        <p>Interview Details</p>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Date</label>
        <input
          type="date"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-[#A1A1A1]"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <div className="flex gap-4 items-center">
          <div className="w-full relative">
            <input
              type="text"
              placeholder="00 : 00"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            <span className="absolute top-3 right-3">PM</span>
          </div>
          <p className="test-[#A1A1A1]">To</p>
          <div className="w-full relative">
            <input
              type="text"
              placeholder="00 : 00"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            <span className="absolute top-3 right-3">PM</span>
          </div>
        </div>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">URL</label>
        <input
          type="text"
          className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Document
        </label>
        <div className="flex flex-col  border border-gray-300 rounded-md p-4 items-center mt-1">
          <SlCloudUpload />
          <p className="font-bold">Drag and drop files or browse files</p>
        </div>
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="mt-4 px-9 py-3 rounded-[2.5rem] bg-[#031B59] text-white  w-fit"
        >
          Schedule
        </button>
      </div>
    </div>
  );
};

export default ClientInterviewPopUp;
