import React, { useEffect } from 'react'
import { AiOutlineAudit } from 'react-icons/ai';
import { BiPurchaseTagAlt, BiWindowClose } from 'react-icons/bi';
import { MdGrading } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardAllCount } from 'reduxToolkit/JobSlice';

const DashboardCount = () => {
  const dispatch = useDispatch();
  const { allSetCount } = useSelector((state) => state.jobs);
  const localData = JSON.parse(localStorage.getItem('userLoginToken')) || {};

  useEffect(() => {
    if (localData?.token) {
      dispatch(dashboardAllCount())
    } 
  }, [])

  const countData = [
    {
      name: "application",
      count: allSetCount?.allApplicants ? allSetCount?.allApplicants : 0,
      icon: AiOutlineAudit
    },
    {
      name: "selected",
      count: allSetCount?.selectedCount ? allSetCount?.selectedCount : 0,
      icon: MdGrading
    },
    {
      name: "on hold",
      count: allSetCount?.onHoldCount ? allSetCount?.onHoldCount : 0,
      icon: BiPurchaseTagAlt
    },
    {
      name: "rejected",
      count: allSetCount?.rejectedCount ? allSetCount?.rejectedCount : 0,
      icon: BiWindowClose
    },
  ];
  return (
    <div className='2xl:w-full sm:w-[75vw] sm:flex  maxWidth1000 h-full gap-6'>
      {
        countData?.map((item, indes) => {
          return (
            <div key={indes} className='w-full sm:max-w-[13.2rem] lg:max-w-[16rem] 2xl:max-w-[17.5rem] h-[10rem]
              bg-white shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] rounded-[1.25rem] flex items-center'>
              <div className='flex justify-around w-full '>
                <div>
                  <h1 className='font-bold text-[#a1a1a1] text-[1rem] uppercase'>{item?.name ? item?.name : "--"}</h1>
                  <p className='font-bold text-[1.5rem]'>{item?.count ? item?.count : 0}</p>
                </div>
                <div>
                  {item?.name?.includes('application') ? (
                    <div className='h-[3.2rem] w-[3.3rem] rounded-full flex justify-center items-center text-[#1B4FB6] text-[1.6rem] border-4 border-[#1B4FB6]'>
                      <AiOutlineAudit />
                    </div>
                  ) : (
                    <>
                      {item?.name?.includes('selected') ? (
                        <div className='h-[3.2rem] w-[3.3rem] rounded-full flex justify-center items-center text-[#1A8718] text-[1.6rem] border-4 border-[#1A8718]'>
                          <MdGrading />
                        </div>
                      ) : (
                        <>
                          {item?.name?.includes('hold') ? (
                            <div className='h-[3.2rem] w-[3.3rem] rounded-full flex justify-center items-center text-[#ED6E0F] text-[1.6rem] border-4 border-[#ED6E0F]'>
                              <BiPurchaseTagAlt />
                            </div>
                          ) : (
                            <>
                              {item?.name?.includes('rejected') ? (
                                <div className='h-[3.2rem] w-[3.3rem] rounded-full flex justify-center items-center text-[#F00] text-[1.6rem] border-4 border-[#F00]'>
                                  <BiWindowClose />
                                </div>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )

}

export default DashboardCount