export const fetchJobData = ({ data, pagination_data }) => {
  if (!data) return { data: [] };

  return {
    data: data.map((obj) => ({
      id: obj.id,
      status: obj.status,
      title: obj.title,
      vacancy: obj.vacancy_count,
      requirements: obj.requirements,
      jobType: obj.job_type,
      createdAt: obj.created_at,
      createdBy: obj.created_by?.name,
      preferredLocation: obj.location,
      applicants: obj.applicants ?? "--",
      allApplicants: obj?.all_applicants
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
  };
};

export const formatAllJobOpeningApplied = (response) => {
  if (!response) return (null);
  const requiredResponse = [];

  response?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      status: obj?.status,
      title: obj?.title,
      vacancy: obj?.vacancy_count,
      requirements: obj?.requirements,
      jobType: obj?.job_type,
      createdAt: obj?.created_at,
      createdBy: obj?.created_by?.name,
      preferredLocation: obj?.location,
      applicants: obj?.applicants ?? "--",
    });
  });

  return requiredResponse;
}

export const singleJobData = (response) => {
  return {
    id: response?.id,
    title: response.title,
    location: response.location,
    status: response.status,
    vacancy: response.vacancy_count,
    requirements: response.requirements,
    jobType: response.job_type,
    description: response.description,
    benefits: response.benefits,
  };
};

export const fetchJobApplications = (response) => {
  const { job_applications } = response;
  const requiredResponse = [];

  job_applications?.map((obj) => {
    requiredResponse.push({
      id: obj?.id,
      fullName: obj?.first_name + " " + obj?.last_name,
      email: obj?.email,
      jobName: obj?.job_name,
      jobFullName: (obj.first_name ? obj.first_name.trim() : '') + " " + (obj.last_name ? obj.last_name.trim() : ''),
      appliedFor: obj?.job_name,
      contactNo: obj?.phone_number,
      noticePeriod: obj?.notice_period,
      expectedSalary: obj?.expected_salary,
      immediateJoiner: obj?.immediate_joiner,
      reference: obj?.reference,
      currentCTC: obj?.current_ctc,
      experience: obj?.experience,
      country: obj?.country,
      state: obj?.state,
      linkedIn: obj?.linkedin_url,
      portFolio: obj?.portfolio_url,
      resumeUrl: obj?.resume_url,
      appliedDate: obj?.created_at,
      preferredLocation: obj?.preferred_location,
      notice_period_negotiable: obj?.notice_period_negotiable,
      job_name: obj?.job_name,
      status: obj?.applicant_status,
      round: obj?.round,
      eventId: obj?.event_id,
      handledBy: obj?.immediate_joiner,
      handledByNew: obj?.handled_by,
      imidiateJoiner: obj.immediate_joiner,
      negotiabled: obj.notice_period_negotiable,
      onBoardDate: obj?.onboarding_date,
      feedbackExist: obj?.feedback_exists
    });
  });


  return {
    requiredResponse,
    inprogressCount: response?.inprogress_count,
    appliedCount: response?.applied_count,
    selectCount: response?.selected_count,
    onholdCount: response?.onhold_count,
    rejectCount: response?.rejected_count,
  }
    ;
};

export const fetchAllJobApplications = ({
  job_applications,
  pagination_data,
}) => {
  return {
    data: job_applications.map((obj) => ({
      id: obj.id,
      fullName: obj.first_name + " " + obj.last_name,
      email: obj.email,
      contactNo: obj.phone_number,
      noticePeriod: obj.notice_period,
      expected_salary: obj.expected_salary,
      immediateJoiner: obj.immediate_joiner,
      reference: obj.reference,
      currentCTC: obj.current_ctc,
      experience: obj.experience,
      country: obj.country,
      state: obj.state,
      linkedUrl: obj.linkedin_url,
      portfolioUrl: obj.portfolioUrl,
      resumeUrl: obj.resume_url,
      appliedDate: obj.created_at,
      notice_period_negotiable: obj.notice_period_negotiable,
      job_name: obj.job_name,
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
  };
};

export const updateJobData = (response) => {
  const jsonObj = {
    job_opening: {
      title: response.title,
      location: response.location,
      status: response.status,
      vacancyCount: response.vacancy_count,
      requirements: response.requirements,
      jobType: response.job_type,
      description: response.description,
      benefits: response.benefits,
    },
  };

  return jsonObj;
};

export const getJobAppApplicants = (response) => {
  const { job_applications, pagination_data } = response;
  return {
    data: job_applications.map((obj) => ({
      id: obj.id,
      title: obj.first_name + " " + obj.last_name,
      contactNo: obj.phone_number,
      jobFullName: (obj.first_name ? obj.first_name.trim() : '') + " " + (obj.last_name ? obj.last_name.trim() : ''),
      appliedFor: obj.job_name,
      jobName: obj.job_name,
      appliedDate: obj.created_at,
      handledBy: obj.immediate_joiner,
      handledByNew: obj.handled_by,
      status: obj.applicant_status,
      resumeUrl: obj.resume_url,
      firstName: obj.first_name,
      lastName: obj.last_name,
      email: obj.email,
      country: obj.country,
      state: obj.state,
      linkedIn: obj.linkedin_url,
      portFolio: obj.portfolio_url,
      currentCTC: obj.current_ctc,
      expectedSalary: obj.expected_salary,
      imidiateJoiner: obj.immediate_joiner,
      experience: obj?.experience,
      noticePeriod: obj.notice_period,
      negotiabled: obj.notice_period_negotiable,
      round: obj?.round,
      preferredLocation: obj?.preferred_location,
      eventId: obj?.event_id,
      reference: obj?.reference,
      onBoardDate: obj?.onboarding_date,
      feedbackExist: obj?.feedback_exists
    })),
    currentPage: pagination_data.current_page,
    itemsPerPage: pagination_data.items_per_page,
    totalCount: pagination_data.total_count,
    totalPages: pagination_data.total_pages,
    inprogressCount: response?.inprogress_count,
    appliedCount: response?.applied_count,
    selectCount: response?.selected_count,
    onholdCount: response?.onhold_count,
    rejectCount: response?.rejected_count,
  };
};

export const fetchgetfeeback = (response) => {
  const requiredResponse = [];
  response?.job_applications_feedbacks?.map((data) => {
    const obj = data?.job_applications_feedback;
    const user = data?.user;
    requiredResponse.push({
      userId: user?.id,
      fullName: user?.full_name,
      email: user?.email,
      profilepictureurl: user?.profile_picture_url,
      id: obj?.id,
      description: obj?.description,
      createdbyid: obj?.created_by_id,
      technicalskills: obj?.technical_skills,
      soft_skills: obj?.soft_skills,
      experienceandknowledge: obj?.experience_and_knowledge,
      problemsolvingandcriticalthinking: obj?.problem_solving_and_critical_thinking,
      projectmanagement: obj?.project_management,
      leadershipandinitiative: obj?.leadership_and_initiative,
      culturalfit: obj?.cultural_fit,
      portfolioUrl: obj?.portfolioUrl,
      performanceunderpressure: obj?.performance_under_pressure,
      avgstars: obj?.avg_stars,
      jobapplicationid: obj?.job_application_id,
      round: obj?.round
    });
  }
  );

  return requiredResponse;
};

export const fetchAllEvent = (response) => {
  const requiredResponse = [];
  response?.data?.map((obj) =>
    requiredResponse.push({
      fullName: obj?.title,
      date: obj?.date,
      id: obj?.id,
      startTime: obj?.start_time,
      endTime: obj?.end_time,
      desc: obj?.description,
      guest: obj?.guests_ids,
      guestEmails: obj?.additional_emails,
      documents: obj?.event_documents,
      url: obj?.meeting_url,
      round: obj?.round,
    })
  );

  return requiredResponse;
};

export const farmatDetailActivity = (response) => {
  const requiredResponse = [];
  response?.map((obj) =>
    requiredResponse.push({
      date: obj?.date,
      activities: obj?.activities?.map((item) => {
        return {
          id: item?.id,
          description: item?.description,
          createdBy: item?.created_by,
          type: item?.type,
          time: item?.time
        }
      })
    })
  );

  return requiredResponse;
};

export const farmatAllCount = (response) => {
  return {
    allApplicants: response?.applications?.all_applications,
    appliedCount: response?.applications?.applied_count,
    inprogressCount: response?.applications?.inprogress_count,
    onHoldCount: response?.applications?.onhold_count,
    rejectedCount: response?.applications?.rejected_count,
    selectedCount: response?.applications?.selected_count,
  };
};

export const formatJobTitle = (response) => {
  const dashboardJobTitleRes = [];
  response?.job_openings?.map((item) => (
    dashboardJobTitleRes?.push({
      jobTitle: item?.title ? item?.title : "",
      applicationCount: item?.Applications ? item?.Applications : 0
    })
  ))

  return dashboardJobTitleRes
};

export const formatNewApplicants = (response) => {
  const dashboardJobTitleRes = [];
  response?.map((item) => (
    dashboardJobTitleRes?.push({
      id: item?.id,
      // firstName: item?.first_name,
      // lastName: item?.last_name,
      email: item?.email ? item?.email : "",
      state: item?.state ? item?.state : "",
      country: item?.country ? item.country : "",
      contactNo: item?.phone_number,
      resumeUrl: item?.resume,
      appliedDate: item?.formatted_applied_date ? item?.formatted_applied_date : "",
      portFolio: item?.portfolio_url,
      linkedIn: item?.linkedin_url,
      jobFullName: (item.first_name ? item.first_name.trim() : '') + " " + (item.last_name ? item.last_name.trim() : ''),
      experience: item?.experience,
      noticePeriod: item?.notice_period,
      expectedSalary: item?.expected_salary,
      negotiabled: item?.notice_period_negotiable,
      preferredLocation: item?.preferred_location,
      currentCTC: item?.current_ctc,
      reference: item?.reference

    })
  ))

  return dashboardJobTitleRes
};


export const formatGraphData = (response) => {
  const dashboardJobTitleRes = [];
  response?.map((item) => (
    dashboardJobTitleRes?.push({
      month: item?.month,
      selected: item?.selected_applications ? item?.selected_applications : 0,
      totalApplication: item?.total_applications ? item?.total_applications : 0,  
    })
  ))

  return dashboardJobTitleRes
};

export const formatOnboard = (response) => {
  // const { job_applications, pagination_data } = response;
  return {
    data: response?.map((obj) => ({
      id: obj?.id,
      title: obj?.first_name + " " + obj?.last_name,
      contactNo: obj?.phone_number,
      jobFullName: (obj?.first_name ? obj?.first_name.trim() : '') + " " + (obj?.last_name ? obj?.last_name.trim() : ''),
      appliedFor: obj?.job_name,
      jobName: obj?.job_name,
      appliedDate: obj?.created_at,
      handledBy: obj?.immediate_joiner,
      handledByNew: obj?.handled_by,
      status: obj?.applicant_status,
      resumeUrl: obj?.resume_url,
      job_titles:obj?.jobTitle,
      firstName: obj?.first_name,
      lastName: obj?.last_name,
      email: obj?.email,
      country: obj?.country,
      state: obj?.state,
      linkedIn: obj?.linkedin_url,
      portFolio: obj?.portfolio_url,
      currentCTC: obj?.current_ctc,
      expectedSalary: obj?.expected_salary,
      imidiateJoiner: obj?.immediate_joiner,
      experience: obj?.experience,
      noticePeriod: obj?.notice_period,
      negotiabled: obj?.notice_period_negotiable,
      round: obj?.round,
      preferredLocation: obj?.preferred_location,
      eventId: obj?.event_id,
      reference: obj?.reference,
      onBoardDate: obj?.onboarding_date,
      feedbackExist: obj?.feedback_exists
    })),
  };
};

export const formatOnboardToday = (response) => {
  // const { job_applications, pagination_data } = response;
  return {
    data: response?.map((obj) => ({
      id: obj?.id,
      title: obj?.first_name + " " + obj?.last_name,
      contactNo: obj?.phone_number,
      jobFullName: (obj?.first_name ? obj?.first_name.trim() : '') + " " + (obj?.last_name ? obj?.last_name.trim() : ''),
      appliedFor: obj?.job_name,
      jobName: obj?.job_name,
      appliedDate: obj?.created_at,
      handledBy: obj?.immediate_joiner,
      handledByNew: obj?.handled_by,
      status: obj?.applicant_status,
      resumeUrl: obj?.resume_url,
      job_titles:obj?.jobTitle,
      firstName: obj?.first_name,
      lastName: obj?.last_name,
      email: obj?.email,
      country: obj?.country,
      state: obj?.state,
      linkedIn: obj?.linkedin_url,
      portFolio: obj?.portfolio_url,
      currentCTC: obj?.current_ctc,
      expectedSalary: obj?.expected_salary,
      imidiateJoiner: obj?.immediate_joiner,
      experience: obj?.experience,
      noticePeriod: obj?.notice_period,
      negotiabled: obj?.notice_period_negotiable,
      round: obj?.round,
      preferredLocation: obj?.preferred_location,
      eventId: obj?.event_id,
      reference: obj?.reference,
      onBoardDate: obj?.onboarding_date,
      feedbackExist: obj?.feedback_exists
    })),
  };
};