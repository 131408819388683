import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { HiDotsHorizontal } from "react-icons/hi";
import { SlCalender } from "react-icons/sl";

const EventPopUp = ({
  handleEventModal,
  setIsEventOpen,
  setIsScedulOpen,
  handleViewEvent,
  setOpenAddEvent,
  toggleEdit,
  setEditPopup,
}) => {
  const [showOptions, setShowOptions] = useState(Array(3).fill(false));
  const [isEventPop, setisEventPop] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [meetings, setMeetings] = useState([
    {
      date: "25",
      month: "Dec",
      bgColor: "#E7F6FD",
      borderColor: "#0EA5E9",
      title: "Meeting Title 1",
      time: "8:00 AM - 9:00 AM",
    },
    {
      date: "25",
      month: "Dec",
      bgColor: "#FEF6E7",
      borderColor: "#F59E0B",
      title: "Meeting Title 2",
      time: "8:00 AM - 9:00 AM",
    },
    {
      date: "25",
      month: "Dec",
      bgColor: "#E8F8F3",
      borderColor: "#10B981",
      title: "Meeting Title 3",
      time: "8:00 AM - 9:00 AM",
    },
  ]);

  const handleToggleOptions = (index) => {
    const updatedShowOptions = showOptions.map((option, i) =>
      i === index ? !option : false
    );
    setShowOptions(updatedShowOptions);
  };
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  return (
    <div className="w-full h-full flex items-center justify-end fixed top-0 left-0 z-40 bg-[rgba(3,27,89,.2)]">
      <div
        className=" w-full max-w-md h-full bg-white flex flex-col
       space-y-4 shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] transition-all"
      >
        <div className=" w-full h-[5rem] px-[8.8] flex justify-between items-center border-b border-[#E2E8F0]">
          <div className="w-full h-[42px] items-center bg-white flex justify-between p-7">
            <div className="flex justify-center items-center">
              <h2 className=" text-[1.5rem] font-bold text-[#031B59]">
                Events
              </h2>
            </div>
            <div
              className="text-[1.5rem] cursor-pointer mr-[-.64rem]"
              onClick={handleEventModal}
            >
              <RxCross2 />
            </div>
          </div>
        </div>
        <div className="p-4">
          <div className="flex items-center space-x-2 text-lg font-normal mb-4 border-b pb-4">
            <div className="flex items-center space-x-2">
              <SlCalender />
              <span>Saturday, 25 Dec 2022</span>
            </div>
            <div className="flex items-center space-x-1">
              <button className="p-1 rounded-full hover:bg-gray-200">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
              <button className="p-1 rounded-full hover:bg-gray-200">
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mb-4">
            <div className="font-medium">Indicator</div>
            <div className="flex flex-col gap-2  mt-2">
              <div className="flex items-center space-x-2 ">
                <div className="w-3 h-3 rounded-full bg-[#0EA5E9]"></div>
                <span className="text-xs">Schedule</span>
              </div>
              <div className="flex items-center space-x-2 ">
                <div className="w-3 h-3 rounded-full bg-[#F59E0B]"></div>
                <span className="text-xs">Candidate Interview</span>
              </div>
              <div className="flex items-center space-x-2 ">
                <div className="w-3 h-3 rounded-full bg-[#10B981]"></div>
                <span className="text-xs"> Client Interview</span>
              </div>
            </div>
          </div>
          <div>
            <div className="font-medium mb-2">Invites</div>
            <div className="space-y-2">
              <div className="flex flex-col gap-3">
                {meetings.map((meeting, index) => (
                  <div
                    key={index}
                    className={`flex items-center space-x-2 p-2 rounded-md w-96`}
                    style={{ backgroundColor: meeting.bgColor }}
                  >
                    <div
                      className="text-white w-12 text-center rounded-md p-1"
                      style={{ backgroundColor: meeting.borderColor }}
                    >
                      <div>{meeting.date}</div>
                      <div>{meeting.month}</div>
                    </div>
                    <div className="flex-1">
                      <div className="font-medium">{meeting.title}</div>
                      <div className="text-sm text-[#646978]">
                        {meeting.time}
                      </div>
                    </div>
                    <div className="relative">
                      <button
                        onClick={() =>
                          setShowOptions(showOptions === index ? null : index)
                        }
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <HiDotsHorizontal />
                      </button>
                      {showOptions === index && (
                        <div className="absolute right-0 mt-2 w-32 bg-white border rounded-lg shadow-lg z-30">
                          <div className="flex flex-col">
                            <button
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                setIsEventOpen(false);
                                setIsScedulOpen(true);
                              }}
                            >
                              View
                            </button>
                            <button
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => setEditPopup(true)}
                            >
                              Edit
                            </button>
                            <button
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() => {
                                setMeetings(
                                  meetings.filter((_item, i) => index !== i)
                                );
                                setShowOptions(null);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventPopUp;
