import ProfileAvtar from "component/common/ProfileAvtar";
import React, {useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useOnClickOutside } from "component/common/useOneClickOutsid";
import { removeHandlers } from "reduxToolkit/JobSlice";
import { GoPlusCircle } from "react-icons/go";

const AddResource = ({
  data,
  resourceData,
  currentIndex,
  id,
  setAgainData,
  setAddResource
}) => {
  const { jobApplications } = useSelector((state) => state.jobs);
  const [ open, setOpen ] = useState(false);

  const dispatch = useDispatch();

  const menuRef = useRef();
  useOnClickOutside(menuRef, () => setOpen(false));
  const { t } = useTranslation();

  const removeHandler = (id,ids) => {
    dispatch(removeHandlers(id,ids,setAgainData));
  };

  return (
    <div>
      {data?.length === 0 ? (
        <span className="cursor-default">
          <button onClick={() => setAddResource(id)}
            className="text-[0.875rem] text-[#031B59] flex items-center cursor-pointer
          justify-start py-4 gap-[0.25rem] "
          >
            <GoPlusCircle />
            <span>Add Resources</span>
          </button>
          </span>
      ) : (
        <div className="relative flex items-center justify-between gap-[4rem]">
          <div className="flex items-center relative w-[2rem]">
            {resourceData?.map(
              (obj, index) =>
                index < 4 && (
                  <div
                    key={index}
                    style={{
                      left: index !== 0 && `${index + 0.25}rem`,
                    }}
                    className="overflow-hidden w-[2rem] h-[2rem] rounded-full absolute"
                  >
                    {obj?.profilePictureUrl ? (
                      <img
                        className=" relative w-[2rem] h-[2rem] object-cover flex items-center
                        border-2 rounded-full border-white"
                        src={obj?.profilePictureUrl}
                        alt="Profile Avatar"
                      />
                    ) : (
                      <ProfileAvtar
                        name={obj?.name ? obj?.name : "User"}
                        height="2rem"
                        width="2rem"
                      />
                    )}
                  </div>
                )
            )}
          </div>
          <div className="cursor-pointer">
            {resourceData?.filter((obj, index) => index >= 4).length > 0 ? (
              <div
                className="text-[#686868] text-[0.875rem]"
                onClick={() => setOpen(!open)}
              >
                {`+${resourceData?.filter((obj, index) => index >= 4).length
                } ${t("more")}`}
              </div>
            ) : (
              <div className=" flex justify-center">
                <IoIosArrowDown
                  className="text-[#A1A1A1]"
                  onClick={() => setOpen(!open)}
                />
              </div>
             )} 
          </div>
        </div>
      )}

      {open && (
        <div
          ref={menuRef}
          className={`h-fit bg-white absolute max-h-[17rem]
        shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] p-1 border-[1px]
        mt-6
        rounded-md border-[#E2E8F0] custom_scroll overflow-y-auto z-10
        ${currentIndex == jobApplications.length - 1 &&
            currentIndex === 9 &&
            "bottom-[4rem]"
        } 
        ${currentIndex == jobApplications.length - 2 &&
            currentIndex === 8 &&
            "bottom-[4rem]"
        }
        ${currentIndex == jobApplications.length - 3 &&
            currentIndex === 7 &&
            "bottom-[4rem]"
        }
        `}
        >
        
          {data?.map((obj, index) => (
            <div
              key={index}
              className="flex hover:bg-[#F8FAFC] items-center justify-center gap-[0.75rem]
            py-[0.56rem] px-[0.94rem]"
            >

              <ProfileAvtar
                src=""
                name={obj?.name ? obj?.name : "User"}
                height="3rem"
                width="3rem"
              />

              <div
                className="flex flex-col items-start justify-center
              gap-[0.31rem] w-[12rem]"
              >
                <p className="font-medium text-[#031B59] text-[0.875rem]">
                  {obj?.name}
                </p>
                {/* <p className="text-[#686868] text-[0.875rem]">{obj?.empCode}</p> */}
              </div>
              <MdDelete
                className="w-[1.5rem] h-[1.5rem] cursor-pointer"
                onClick={() => removeHandler(id,obj.id)}
              />
            </div>
          ))}
            <button onClick={() => setAddResource(id)}
            className=" hover:bg-[#F8FAFC] text-[1rem] text-[#031B59] flex items-center cursor-pointer
           w-full justify-center p-[0.5rem] gap-[0.30rem] "
          >
            <GoPlusCircle />
            <span>Add Resources</span>
          </button>
        </div>

      )}
    </div>
  );
};

export default AddResource;

AddResource.propTypes = {
  showDropdown: PropTypes.string,
  data: PropTypes.object,
  resourceData: PropTypes.array,
  onDropdownClick: PropTypes.func,
  onDelete: PropTypes.func,
  currentIndex: PropTypes.number,
  setShowDropdown: PropTypes.func,
  status: PropTypes.any,
  currentPage: PropTypes.number,
  setLoading: PropTypes.func,
  id: PropTypes.number,
  setAgainData: PropTypes.any,
};
