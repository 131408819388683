// import React from 'react'
import { useTranslation } from 'react-i18next';
import DashboardJobTitle from './DashboardJobTitle';
import React, { useEffect } from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardGraph } from 'reduxToolkit/JobSlice';


const DashboardGraphOpening = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { allGraphData } = useSelector((state) => state.jobs);
  const localData = JSON.parse(localStorage.getItem('userLoginToken')) || {};



  const allGraphReverseData = [...allGraphData].reverse();

  useEffect(() => {
    if (localData?.token) {
      dispatch(dashboardGraph())
    }
  }, [])

  let theMonths = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
  let findInde = theMonths.indexOf(allGraphReverseData[0]?.month);
  let arr = [...theMonths.slice(findInde + 1, theMonths.length), ...theMonths.slice(0, findInde + 1)];
  let dataData = arr.map((item) => {
    const val = allGraphReverseData.find((m) => m.month === item)
    if (!!val) {
      return {
        name: val?.month?.substring(0, 3),
        uv: val?.selected,
        pv: val?.totalApplication,
        amt: 0,
      }
    }
    else {
      return {
        name: item?.substring(0, 3),
        uv: 0,
        pv: 0,
        amt: 0,
      }
    }

  }
  )

  return (
    <div className='w-full h-fit bg-white shadow-[0_0px_20px_0px_rgba(3,27,89,0.10)] rounded-[1.25rem] p-[1.25rem] flex flex-col gap-1'>
      <div className='flex justify-between items-center'>
        <h1 className='text-lg font-semibold text-[#031b59]'>{t('currentOpening')}</h1>
        <span className='p-2 bg-[#f2f6fa] rounded-[10px] cursor-pointer'>{t('view')}</span>
      </div>
      <div className='flex items-center gap-5'>
        <div className='flex items-center gap-2'>
          <div className='h-[0.8rem] w-[0.8rem] rounded-full bg-[#1b4fb6]'></div>
          <h1 className=' text-[#031b59]'>{t('applicationForJobTitle')}</h1>
        </div>
        <div className='flex items-center gap-2'>
          <div className='h-[0.8rem] w-[0.8rem] rounded-full bg-[#E6E8EC]'></div>
          <h1 className=' text-[#031b59]'>{t('shortListed')}</h1>
        </div>
      </div>
      <div className='graphSize h-[17.2rem]'>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={dataData}
            barGap={0}
            barSize={15}
            margin={{
              top: 15,
              right: 10,
              left: -7,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis type="number" />
            <Tooltip />
            {/* <Legend /> */}
            <Bar dataKey="pv" fill="#1b4fb6" activeBar={<Rectangle fill="#1b4fb6" stroke="blue" />} />
            <Bar dataKey="uv" fill="#E6E8EC" activeBar={<Rectangle fill="#E6E8EC" stroke="purple" />} />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div>
        <DashboardJobTitle />
      </div>
    </div>
  )
}

export default DashboardGraphOpening;

