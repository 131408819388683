import React from "react";
import { RxCross2 } from "react-icons/rx";
import { SlCloudUpload } from "react-icons/sl";

const CandidateInterviewPopUp = () => {
  return (
    <div className="p-4 pt-0 rounded-md">
      <div className="flex flex-col gap-5">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Candidate Name
          </label>
          <input
            type="text"
            placeholder="Candidate Name"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Document
          </label>
          <div className="flex flex-col  border border-gray-300 rounded-md p-4 items-center mt-1">
            <SlCloudUpload />
            <p className="font-bold">Drag and drop files or browse files</p>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Title
          </label>
          <input
            type="text"
            placeholder="UI/ UX  Interview ( Round-1 )"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Date
          </label>
          <input
            type="date"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md text-[#A1A1A1]"
          />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Start Time
            </label>
            <div className="w-full relative">
              <input
                type="text"
                placeholder="00 : 00"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />

              <span className="absolute top-3 right-3">PM</span>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              End Time
            </label>
            <div className="w-full relative">
              <input
                type="text"
                placeholder="00 : 00"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
              />
              <span className="absolute top-3 right-3">PM</span>
            </div>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            placeholder="Placeholder"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Add guests
          </label>
          <div className="flex flex-wrap gap-2 mt-1 border border-gray-300 p-1 rounded-md">
            <span className="flex gap-2 items-center px-2 py-1 bg-blue-100  rounded-full">
              <img
                src="https://via.placeholder.com/40"
                alt="profile"
                className="h-5 w-5 rounded-full"
              />
              <span className="mr-2">mitali</span>
              <button type="button">
                <RxCross2 />
              </button>
            </span>
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Round1
          </label>
          <input
            type="text"
            placeholder="1"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">URL</label>
          <input
            type="text"
            placeholder="url.com"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Resume
          </label>
          <div className="w-full relative">
            <input
              type="text"
              value="Document.pdf"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            <span className="absolute top-3 right-3">
              <SlCloudUpload />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateInterviewPopUp;
